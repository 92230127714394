<template>
  <main
    id="page-search"
    class="bg-bgr-50 shadow-inner"
    :data-filters-count="Object.keys(filters).length"
  >
    <header class="bg-hlt-light text-hlt">
      <!-- TODO: Delete "Redesigned SRP" bar before release -->
      <p class="container-fluid-lg flex py-3 justify-center">
        Redesigned Search Result Page
      </p>
    </header>

    <div
      v-if="$params.iframe"
      class="container-fluid-lg flex flex-row items-center justify-between"
    >
      <SearchBreadcrumb :geo="geo" />
      <BookmarkLink />
    </div>

    <section class="container-fluid-lg flex gap-6">
      <aside class="hidden md:flex">
        <Filter
          :filters="filters"
          :facets="search.facets"
          :countries="search.geo.countries"
          :regions="search.geo.regions"
          :places="search.geo.places"
        />
      </aside>
      <div class="w-full">
        <header class="flex flex-col-reverse md:flex-col">
          <section>
            <SearchResultsHeader :total="total" />
            <SearchResultsHeaderCount :total="total" />
          </section>
          <section class="flex items-center gap-2 mt-4 md:justify-between">
            <button
              class="flex items-center justify-between rounded-full border border-bgr-300 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover h-11 px-4 md:hidden text-sm"
            >
              <WebccIcon name="searchpage/settings" class="h-4 w-4 mr-2" />
              <span>{{ $t('www.components.views.search.area.filters') }}</span>
            </button>
            <SearchResultLayoutSwitch v-model="layout" class="hidden md:flex" />
            <SearchSorting
              v-model="filters"
              rounded
              mode="right"
              class="shrink min-w-0"
            />
          </section>
        </header>

        <main class="sticky top-4 w-full mt-4">
          <SearchLoader
            v-if="search.loading"
            :blur="!!search.total"
            class="absolute z-[15] w-full h-full min-h-356"
          />
          <SearchResults v-if="search.total" :layout="layout" />
          <SearchAreaNoResult v-else />
        </main>
      </div>
    </section>

    <SearchRatingBox :key="filtersKey" />

    <SearchTeaser v-if="!$params.iframe">
      <SearchBreadcrumb :geo="geo" />
      <template #searchteaser>
        <SearchObjectTeasers
          v-if="objectTeasersEnabled"
          :key="destinationFilterKey"
          :show="objectTeasersVisible"
          :destination="destination"
        />
      </template>
      <template #contentassets>
        <SearchContentAssets
          v-if="contentAssetsEnabled"
          :key="destinationFilterKey"
          :filter="destinationFilter"
        />
      </template>
    </SearchTeaser>

    <USPCards :why="additionalBody.USPCards.cards" />

    <USPWhy
      :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]"
    />

    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const LAYOUT_DEFAULT = 'grid'

const OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY = 10
const OBJECT_TEASERS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'skiing',
  'familyfriendly',
])

const CONTENT_ASSETS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'familyfriendly',
])

const confData = useConfdata()
const search = useSearch()
const ab = useAB()

const layout = ref<SearchResultLayout>(LAYOUT_DEFAULT)

const geo = computed(() => search.geoCurrent)
const searchUrl = computed(() => search.url)
const total = computed(() => search.total ?? 0)
const flexTripFilter = computed(() => useFlexTrip().filter)
const additionalBody = computed(() => confData.additionalBody as SearchPageBody)
const destination = computed(() => confData.destination)
const destinationFilter = computed(() => destination.value?.filters?.[0].key)
const destinationFilterKey = computed(() => destination.value.urlPath)
const route = computed(() => useRoute())

const filters = computed({
  get: () => removeFalsy(search.filters),
  set: (value: Params) => search.setFilter(value),
})

const filtersKey = computed(() => JSON.stringify(filters.value))

const objectTeasersEnabled = computed(() =>
  Boolean(
    ab.isActive('objectteaser') &&
      route.value.path && // handeleing reactivity, see https://jira.migros.net/browse/IHGWEBCC-1382
      confData.isCanonicalPage(useRequestURL()) &&
      (!destinationFilter.value ||
        OBJECT_TEASERS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)

const objectTeasersVisible = computed(
  () =>
    objectTeasersEnabled.value &&
    total.value >= OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY,
)

const contentAssetsEnabled = computed(() =>
  Boolean(
    useFlag('content-assets').value &&
      destination.value?.countryCode &&
      (!destinationFilter.value ||
        CONTENT_ASSETS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)

watch(route, () => window?.scrollTo({ top: 0 }), {
  deep: true,
  immediate: true,
})

watch(flexTripFilter, (newFilter, oldFilter) => {
  if (
    objectsEqual(newFilter, oldFilter) ||
    Object.values(newFilter).every((v) => !v)
  )
    return

  nextTick(() => search.setFilter(newFilter as unknown as Params))
})

watch(filters, () => (useFlexTrip().range = getTripDateRange(filters.value)))

watch(searchUrl, async (newValue, oldValue) => {
  if (!newValue || newValue === oldValue) return

  useNavigation().navigateToURL(new URL(newValue))
  await confData.load()
  usePageHead().update(confData.head)
})

onMounted(() => {
  window.onbeforeunload = function () {
    // Before a page-reload the browser normally memorises the scroll-position, but on the reloaded searchpage this may have unwanted sideeffects cause of dynamic content.
    // So it is necessary to set the scroll-position to 0 before unload (which leads to position top on reload)
    window.scrollTo(0, 0)
  }
})
</script>

<template>
  <div @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div
      ref="scroller"
      class="overflow-x-scroll hide-scrollbar relative flex w-full snap-x snap-proximity scroll-px-2 gap-2 scroll-smooth"
      @scroll="scrollCounter++"
      @scrollend="emitItemsDisplay"
    >
      <slot />
    </div>
    <template v-for="direction in visibleButtons" :key="direction">
      <div
        v-if="fade"
        class="absolute top-0 h-full w-1/2 from-transparent from-90% to-bgr pointer-events-none"
        :class="[`${direction}-0`, `bg-gradient-to-${direction.at(0)}`]"
      />
      <div class="absolute top-1/2" :class="`${direction}-0 md:${direction}-4`">
        <WebccButton
          variant="white"
          :icon="`searchpage/chevron-${direction}-large`"
          round
          @click="scrollTo(direction)"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    step?: number
    fade?: boolean
    buttons?: boolean | 'hover'
  }>(),
  {
    step: 200,
    fade: false,
    buttons: 'hover',
  },
)

const emit = defineEmits<{
  scrollClick: [direction: ScrollDirectionHorizontal]
  itemsDisplay: [indexes: number[]]
}>()

const scroller = ref<HTMLDivElement>()
const intersectionObserver = useIntersectionObserver(scroller, ([entry]) => {
  if (entry?.isIntersecting) emitItemsDisplay()
})

const hovering = ref(false)
const scrollCounter = ref(0)

const visibleButtons = computed(() => {
  if (!scroller.value) return []
  if (!scrollCounter.value) return []
  if ((props.buttons === 'hover' && !hovering.value) || !props.buttons)
    return []

  const { scrollLeft, offsetWidth, scrollWidth } = scroller.value
  return [
    scrollLeft ? 'left' : undefined,
    scrollLeft + offsetWidth !== scrollWidth ? 'right' : undefined,
  ].filter(Boolean) as ScrollDirectionHorizontal[]
})

onMounted(() => scrollCounter.value++)

onBeforeUnmount(() => intersectionObserver.stop())

function scrollTo(direction: ScrollDirectionHorizontal) {
  emit('scrollClick', direction)

  if (!scroller.value) return

  scroller.value.scrollLeft += (direction === 'left' ? -1 : 1) * props.step
}

function emitItemsDisplay() {
  if (!scroller.value?.children.length) return

  const [{ clientWidth }] = scroller.value.children
  const { offsetWidth, scrollLeft } = scroller.value
  const start = Math.floor(scrollLeft / clientWidth)
  const count = Math.round(offsetWidth / clientWidth)
  const indexes = [...Array(count).keys()].map((i) => start + i)
  emit('itemsDisplay', indexes)
}
</script>

<template>
  <section
    v-if="enabled"
    v-track:impression="'search:seoContentImpression'"
    class="container-fluid-lg mb-14"
  >
    <WebccHeading
      :level="2"
      :space="'mt-0 mb-6'"
      class="text-txt-stronger font-medium"
    >
      <span>{{ $t(`${trPrefix}introHeading`, { destination }) }}</span>
    </WebccHeading>
    <p class="text-base">{{ text }}</p>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.content.SightsAndContent.'

const props = defineProps<{
  modelValue: ContentAssetsIntro
}>()

const { text } = props.modelValue

const enabled = useFlag('content-assets-intro-text')
const destination = computed(() =>
  getDestinationName(useConfdata().destination),
)
</script>

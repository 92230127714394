<template>
  <WebccIcon v-if="isVisible" :name="`attributes/${icon}`" :class="size" />
</template>

<script setup lang="ts">
const props = defineProps<{
  accom: ResultDocument
  small?: boolean
}>()

const isVisible = computed(() => props.accom.tags.includes('sustainable'))
const icon = computed(() => (props.small ? 'ecomodation-small' : 'ecomodation'))
const size = computed(() => (props.small ? 'h-6 w-8' : 'h-10 w-20'))
</script>

<template>
  <div
    v-if="pagesTotal > 0"
    class="max-w-full gap-4"
    :class="isGridView ? 'card-container ' : 'flex flex-col'"
  >
    <template
      v-for="accom in docs"
      :key="JSON.stringify({ code: accom.code, trip: accom.trip })"
    >
      <AccomCard
        v-track:impression="{ event: 'search:objectImpression' }"
        :accom
        :layout
        :currency="confData.currency"
        :show-trip-tag
      />
    </template>
  </div>
  <WebccPagination
    v-if="pagesTotal > 1"
    class="mb-16 mt-3"
    :active="pageActive"
    :total="pagesTotal"
    :unique-key="JSON.stringify(search.filters)"
    @select-page="loadPage"
  />
</template>

<script setup lang="ts">
const props = defineProps<{ layout: SearchResultLayout }>()

const confData = useConfdata()
const search = useSearch()
const screen = useScreen()
const tracking = useTracking()
const flexTrip = useFlexTrip()

const startRef = ref<HTMLDivElement>()

const { totalPages, filters } = storeToRefs(search)

const isGridView = computed(() => props.layout === 'grid' || !screen.isLarge)
const docs = computed(() => search.docs ?? [])
const checkin = computed(() => tryParseDate(filters.value.checkin))
const checkout = computed(() => tryParseDate(filters.value.checkout))

const pageActive = computed(
  () => tryParseInt(filters.value.page?.toString()) ?? 1,
)

const showTripTag = computed(() => {
  return !!(flexTrip.flexibleCalendar && checkin && checkout)
})

const pagesTotal = computed(() => totalPages.value ?? 0)

async function loadPage(page: number) {
  tracking.handlers?.search.paging({ current: page, total: pagesTotal.value })
  await search.setFilter({ page })
  startRef.value?.scrollIntoView({ behavior: 'smooth' })
}
</script>

<style scoped>
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
</style>

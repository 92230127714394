<template>
  <div class="relative">
    <WebccModal class="w-screen" data-id="modal" @close="handleClose">
      <div
        v-if="mode === 'multi'"
        class="relative flex h-screen w-screen flex-col rounded bg-bgr px-4 py-4"
        @click.stop
      >
        <div
          class="no-print absolute right-5 top-5 mx-auto flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full"
          @click="handleGalleryClose"
        >
          <WebccIcon class="h-6 w-6" name="site/x" />
        </div>
        <div
          class="no-print mb-4 flex flex-col gap-1 px-4 md:flex-row md:items-center md:px-0"
        >
          <div class="w-3/4">
            <span class="font-semibold text-txt-strongest">{{
              $t(`${trPrefix}images`, { n: images.length.toString() })
            }}</span>
            <span class="font-semibold text-txt-weak">{{
              $t(`${trPrefix}clickForFullsize`)
            }}</span>
          </div>
          <div class="ml-auto flex gap-2 md:mr-12">
            <client-only v-if="showBookmark && useStorageReady()">
              <WebccButton
                :variant="isBookmarked ? 'theme' : ''"
                icon="site/bookmark"
                icon-filled
                class="flex items-center"
                size="sm"
                @click="handleBookmark"
              >
                <span>{{
                  $t(
                    `${trPrefix}${isBookmarked ? 'bookmarkDel' : 'bookmarkAdd'}`,
                  )
                }}</span>
              </WebccButton>
            </client-only>
            <DetailsSocialShare
              v-if="showSocialShare"
              size="sm"
              @open="tracking.handlers?.detail.galleryOpenRecommend()"
            />
          </div>
        </div>
        <section
          id="section"
          class="grid h-full grid-cols-2 gap-2 overflow-y-auto"
        >
          <div
            v-for="(i, index) in images"
            :key="i.id"
            class="flex w-full justify-end even:justify-start"
          >
            <img
              ref="image"
              loading="lazy"
              :srcset="`${imgM(i.id)}, ${imgL(i.id)}, ${imgXL(i.id)}`"
              :src="imgDefault(i.id)"
              :title="i.title"
              :alt="i.alt"
              :sizes="sizes"
              class="swiper-lazy aspect-[4/3] max-h-[540px] cursor-pointer object-cover sm:aspect-[1/1] md:aspect-[4/3]"
              @click="
                () => {
                  openLightbox(index)
                  tracking.handlers?.detail.galleryImageClick()
                }
              "
              @load="checkScrollTo(i.id, index)"
            />
          </div>
        </section>
      </div>
      <div v-else data-id="lightbox">
        <a
          v-for="i in images"
          :key="i.id"
          :data-pswp-width="1600"
          :data-pswp-height="1200"
          :data-pswp-srcset="`${imgM(i.id)}, ${imgL(i.id)}, ${imgXL(i.id)}, ${img2XL(i.id)}`"
          :href="imgDefault(i.id)"
          target="_blank"
          rel="noreferrer"
        >
        </a>
      </div>
    </WebccModal>
    <WebccModal
      v-if="share"
      :scrolling="{ onopen: false, onclose: false }"
      @close="share = false"
    >
      <div
        class="container-fluid-sm relative flex flex-col gap-4 rounded bg-bgr p-4"
      >
        <div
          class="absolute right-4 top-4 mx-auto flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full"
          @click="share = false"
        ></div>
        <div class="flex flex-row items-center justify-between">
          <span class="text-xl font-semibold">{{
            $t(`${trPrefix}share`)
          }}</span>
          <WebccIcon class="h-6 w-6" name="site/x" />
        </div>
        <div class="flex flex-row items-center gap-4">
          <div>
            <img
              class="h-24 rounded shadow"
              :src="
                `${useRuntimeConfig().public.imagekitBase}/` +
                images[0].id +
                '/tr:h-96,cm-pad_resize,pr-true'
              "
              :alt="images[0].alt"
              :title="images[0].title"
            />
          </div>
          <span>
            {{ accom.name }}
          </span>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
          <WebccLink
            anchor
            absolute
            :to="`https://www.facebook.com/sharer/sharer.php?u=${target}`"
            target="_blank"
            class="rounded-md border border-bgr-300 bg-bgr-200 px-4 py-8 text-txt shadow hover:bg-bgr-100 focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger"
          >
            <div class="flex flex-row items-center gap-2">
              <WebccIcon
                class="h-10 w-10 text-[#3b5998]"
                name="social/facebook-share"
              />
              <span class="font-semibold">{{ $t(`${trPrefix}facebook`) }}</span>
            </div>
          </WebccLink>
          <WebccLink
            anchor
            absolute
            :to="`https://x.com/intent/tweet?text=${target}`"
            target="_blank"
            class="rounded-md border border-bgr-300 bg-bgr-200 px-4 py-8 text-txt shadow hover:bg-bgr-100 focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger"
          >
            <div class="flex flex-row items-center gap-2">
              <WebccIcon
                class="h-10 w-10 text-[#000000]"
                name="social/x-logo-share"
              />
              <span class="font-semibold">{{ $t(`${trPrefix}twitter`) }}</span>
            </div>
          </WebccLink>
          <WebccLink
            anchor
            absolute
            :to="`mailto:?body=${target}`"
            target="_blank"
            class="rounded-md border border-bgr-300 bg-bgr-200 px-4 py-8 text-txt shadow hover:bg-bgr-100 focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger"
            @click.stop="tracking.handlers?.detail.recommendByMail()"
          >
            <div class="flex flex-row items-center gap-2">
              <WebccIcon class="h-10 w-10" name="social/mail-share" filled />
              <span class="font-semibold">{{ $t(`${trPrefix}email`) }}</span>
            </div>
          </WebccLink>
          <WebccLink
            anchor
            absolute
            :to="whatsAppLink"
            target="_blank"
            class="rounded-md border border-bgr-300 bg-bgr-200 px-4 py-8 text-txt shadow hover:bg-bgr-100 focus:border-bgr-700 focus:bg-bgr-100 focus:text-txt-stronger"
          >
            <div class="flex flex-row items-center gap-2">
              <WebccIcon class="h-10 w-10" name="social/whatsapp" filled />
              <span class="font-semibold">{{ $t(`${trPrefix}whatsapp`) }}</span>
            </div>
          </WebccLink>
        </div>
      </div>
    </WebccModal>
  </div>
</template>

<script setup lang="ts">
import type { UIElementData } from 'photoswipe'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import type { TranslatedAccommodationWithPrice } from '~/resources/tracking/ecommerce'

const trPrefix = 'www.components.views.details.ModalGallery.'

const { t } = useI18n()

interface Props {
  accom: TranslatedAccommodation
  checkin?: Date
  scrollToImg?: string
  isBookmarked?: boolean
  showBookmark?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  checkin: undefined,
  scrollToImg: '',
  isBookmarked: false,
  showBookmark: true,
})

const emit = defineEmits<{
  close: []
  'toggle-bookmark': []
}>()

const tracking = useTracking()

// Refs
// const galleryOpen: Ref<boolean> = ref(false)
const mode: Ref<string> = ref('multi')
const target: Ref<string> = ref('')
const share: Ref<boolean> = ref(false)
const lightbox: Ref<PhotoSwipeLightbox | null> = ref(null)
const image = ref()
const partnerid = usePartner().id

onMounted(() => {
  target.value =
    window.location.href.split('?')[0] +
    (partnerid ? `?partnerid=${partnerid}` : '')
  if (!lightbox.value) {
    lightbox.value = new PhotoSwipeLightbox({
      gallery: '[data-id="lightbox"]',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      zoom: false,
      close: true,
      bgOpacity: 1,
      bgClickAction: () => {
        tracking.handlers?.detail.closeLayers()
        lightbox.value?.pswp?.close()
      },
      clickToCloseNonZoomable: false,
      appendToEl: document.querySelector('[data-id="modal"]')! as HTMLElement,
      closeSVG: `<svg class="text-txt-contrast" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`,

      closeTitle: t(`${trPrefix}close`),
      arrowPrevTitle: t(`${trPrefix}previous`),
      arrowNextTitle: t(`${trPrefix}next`),
      errorMsg: t(`${trPrefix}loadError`),
    })
    lightbox.value.addFilter('isContentZoomable', () => {
      return false
    })
    lightbox.value.addFilter(
      'uiElement',
      (element: HTMLElement, data: UIElementData) => {
        if (data.name === 'arrowNext') {
          element.addEventListener('click', () => {
            tracking.handlers?.detail.lightboxNextImage()
          })
        } else if (data.name === 'arrowPrev') {
          element.addEventListener('click', () => {
            tracking.handlers?.detail.lightboxPreviousImage()
          })
        } else if (data.name === 'close') {
          element.addEventListener('click', () => {
            tracking.handlers?.detail.lightboxClose()
          })
        }
        return element
      },
    )
    lightbox.value.on('close', () => {
      closeModal()
    })
    lightbox.value.on('uiRegister', () => {
      if (props.showBookmark) {
        lightbox.value?.pswp?.ui?.registerElement({
          name: 'bookmark',
          ariaLabel: '',
          order: 9,
          isButton: true,
          html: props.isBookmarked
            ? `<svg class="text-txt-contrast" viewBox="0 0 24 24" width="24" height="24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>`
            : `<svg class="text-txt-contrast" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>`,
          onClick: (_: MouseEvent, el: HTMLElement) => {
            el.innerHTML = props.isBookmarked
              ? `<svg class="text-txt-contrast" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>`
              : `<svg class="text-txt-contrast" viewBox="0 0 24 24" width="24" height="24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>`
            if (props.isBookmarked) {
              tracking.handlers?.ecommerce.removeFromWishlist([
                props.accom as TranslatedAccommodationWithPrice,
              ])
              tracking.handlers?.detail.galleryRemoveFavorite()
            } else {
              tracking.handlers?.ecommerce.addToWishlist([
                props.accom as TranslatedAccommodationWithPrice,
              ])
              tracking.handlers?.detail.galleryAddFavorite()
            }
            emit('toggle-bookmark')
          },
        })
      }
      lightbox.value?.pswp?.ui?.registerElement({
        name: 'share',
        ariaLabel: '',
        order: 9,
        isButton: true,
        html: `
          <svg width="20" height="20" stroke="currentColor" class="text-txt-contrast" xmlns="http://www.w3.org/2000/svg"	xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 11.3386 11.3386">
            <path d="M9.09,7.18a1.9015,1.9015,0,0,0-1.5387.8151L4.0483,6.2436A1.8083,1.8083,0,0,0,4.15,5.67a1.8551,1.8551,0,0,0-.1033-.5826l3.502-1.7508A1.88,1.88,0,0,0,9.09,4.16,1.9248,1.9248,0,1,0,7.17,2.23a1.7972,1.7972,0,0,0,.1026.5736L3.7671,4.5557A1.9148,1.9148,0,0,0,2.22,3.74a1.9248,1.9248,0,0,0,0,3.85,1.8778,1.8778,0,0,0,1.551-.8136l3.501,1.75A1.8112,1.8112,0,0,0,7.17,9.1,1.9248,1.9248,0,1,0,9.09,7.18Zm0-6.27a1.3252,1.3252,0,1,1,0,2.65,1.2941,1.2941,0,0,1-1.1757-.7535A1.1659,1.1659,0,0,1,7.77,2.23,1.3221,1.3221,0,0,1,9.09.91ZM2.22,6.99a1.3252,1.3252,0,1,1,1.187-1.9067A1.2287,1.2287,0,0,1,3.55,5.67a1.1753,1.1753,0,0,1-.1509.59A1.2932,1.2932,0,0,1,2.22,6.99Zm6.87,3.44A1.3271,1.3271,0,0,1,7.77,9.1a1.1718,1.1718,0,0,1,.15-.5879,1.313,1.313,0,0,1,1.17-.7324,1.3252,1.3252,0,1,1,0,2.65Z"/>
          </svg>`,
        onClick: () => {
          if (!share.value) {
            tracking.handlers?.detail.lightboxOpenRecommend()
          }
          share.value = true
        },
      })
    })
    lightbox.value.init()
  }
})

onBeforeUnmount(() => {
  if (lightbox.value) {
    lightbox.value?.destroy()
    lightbox.value = null
  }
})

const showSocialShare = computed(() => {
  return !useParams().iframe
})

const whatsAppLink = computed(
  () =>
    `https://api.whatsapp.com/send/?text=${t('www.components.views.details.SocialShare.whatsApp')}${target.value}`,
)

const { images } = useAccomImageCollection(() =>
  toAccomData(props.accom, useSeason(props.checkin)),
)

const sizes: ComputedRef<string> = computed(() => {
  return '(min-width: 769px) 1024px, (min-width: 577px) 768px, 420px'
})

// Functions
function checkScrollTo(imgId: string, index: number) {
  if (imgId === props.scrollToImg) {
    nextTick(() => {
      if (image.value && image.value[index])
        image.value[index].scrollIntoView({ behavior: 'smooth' })
    })
  }
}

function closeModal() {
  emit('close')
}

function imgDefault(id: string) {
  return `${useRuntimeConfig().public.imagekitBase}/${id}/tr:h-450,w-600,pr-true,fo-auto 768w`
}

function img2XL(id: string) {
  return `${useRuntimeConfig().public.imagekitBase}/${id}/tr:h-1200,w-1600,pr-true,fo-auto 1200w`
}

function imgXL(id: string) {
  return `${useRuntimeConfig().public.imagekitBase}/${id}/tr:h-540,w-720,pr-true,fo-auto 1024w`
}

function imgL(id: string) {
  return `${useRuntimeConfig().public.imagekitBase}/${id}/tr:h-450,w-600,pr-true,fo-auto 768w`
}

function imgM(id: string) {
  return `${useRuntimeConfig().public.imagekitBase}/${id}/tr:h-340,w-450,pr-true,fo-auto 576w`
}

function openLightbox(index: number) {
  mode.value = 'single'
  nextTick(() => {
    const gallery = document.querySelector('[data-id="lightbox"]')
    if (gallery instanceof HTMLElement) {
      lightbox.value?.loadAndOpen(index, { gallery })
    }
  })
}

function handleBookmark() {
  emit('toggle-bookmark')
  const handlerFunction = props.isBookmarked
    ? tracking.handlers?.ecommerce.removeFromWishlist
    : tracking.handlers?.ecommerce.addToWishlist
  handlerFunction?.([props.accom as TranslatedAccommodationWithPrice])
}

function handleClose() {
  tracking.handlers?.detail.closeLayers()
  closeModal()
}

function handleGalleryClose() {
  tracking.handlers?.detail.galleryClose()
  closeModal()
}
</script>

<style>
.pswp__top-bar {
  .bg-black-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.pswp--touch .pswp__button--arrow {
  visibility: visible;
}

@media print {
  #__nuxt {
    display: none !important;
  }

  [data-id='modal'] {
    position: static !important;
    overflow: visible !important;
    box-shadow: none;
    top: 0;
    left: 0;
  }

  img {
    max-width: 90% !important;
    height: auto !important;
  }

  .items-center {
    align-items: initial !important;
  }

  .no-print {
    display: none !important;
  }

  .h-screen,
  .h-full {
    height: auto !important;
  }

  .overflow-y-auto {
    overflow-y: initial !important;
  }

  .py-4,
  .px-4 {
    padding: 0 !important;
  }

  body,
  html {
    margin: 0;
    padding: 0;
  }
}
</style>

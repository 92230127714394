<template>
  <button
    class="border rounded-full bg-bgr opacity-80 hover:bg-bgr-100 transition focus:bg-bgr-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thm-hover"
  >
    <WebccIcon
      filled
      :name="isBookmarked ? 'site/bookmark-filled' : 'site/bookmark'"
      class="h-8 w-8 p-1 stroke-[1.5]"
      @click.stop.prevent="toggleBookmark"
    />
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{
  accom: ResultDocument
}>()

const bookmarks = useBookmarks()
const tracking = useTracking()

const isBookmarked = computed(() => bookmarks.has(props.accom.code))

function toggleBookmark() {
  bookmarks.toggle(props.accom.code)

  if (isBookmarked.value) {
    tracking.handlers?.ecommerce.removeFromWishlist([props.accom])
  } else {
    tracking.handlers?.ecommerce.addToWishlist([props.accom])
  }
  tracking.handlers?.search.toggleBookmark({
    accom: props.accom.code,
    state: !isBookmarked.value,
  })
}
</script>

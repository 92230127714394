import { trim } from 'lodash-es'
import { format } from 'date-fns'
import type { EventData } from '.'
import type { UnparsedDate } from '~/utils/dates'

type Type = 'A' | 'B' | 'G' | 'H'
type DetailType = 'A' | 'B' | 'C' | 'D' | 'F' | 'H' | 'R' | 'S' | 'V' | 'Y'

export type TranslatedAccommodationWithPrice = AccomPrice &
  TranslatedAccommodation &
  Partial<TripParams>

export interface ProductItem {
  item_id: string
  item_name: string
  discount?: number
  item_brand: string
  item_category?: string
  price: number
  quantity: number
  item_end_date?: string
  item_start_date?: string
  item_quality: number
  item_currency: string
  item_pax: string
  pool?: boolean
  item_regular_price?: number
}

export interface ProductPromotion {
  creative_name: string
  creative_slot: string
  promotion_id: string
  promotion_name: string
}

export interface ViewItemListData extends EventData {
  ecommerce: {
    item_list_id: string
    item_list_name: string
    items: ProductItem[]
  } | null
}

export interface ViewItemData extends EventData {
  ecommerce: {
    currency: string
    value: number
    items: ProductItem[]
  }
}

const detailTypeMapping: Record<DetailType, string> = {
  A: 'apart-hotel',
  B: 'bungalow',
  C: 'chalet',
  D: 'divers',
  F: 'farmhouse',
  H: 'holiday-village',
  R: 'residence',
  S: 'castle-mansion',
  V: 'villa',
  Y: 'yacht',
}

const typeMapping: Record<Type, string> = {
  A: 'apartment',
  B: 'boat',
  G: 'guestroom',
  H: 'holiday-house',
}

function dateToString(value: UnparsedDate | undefined): string {
  const date = tryParseDate(value)

  return date ? format(date, 'yyyy-MM-dd') : ''
}

function brand(type: Type, detailType?: DetailType) {
  if (detailType) {
    return `${typeMapping[type]}/${detailTypeMapping[detailType]}`
  }
  return `${typeMapping[type]}`
}

function accomodationCategory(accommodation: TranslatedAccommodationWithPrice) {
  const type = typeMapping[accommodation.type.code as Type]
  const detailType = detailTypeMapping[accommodation.type.detail as DetailType]
  const country = seoify(accommodation.country.label)
  const region = seoify(accommodation.region.label)
  const place = seoify(accommodation.place.label)
  return `${country}/${region}/${place}/${type}/${detailType}`
}

function addToCart(raw: TranslatedAccommodationWithPrice): void {
  const data: ViewItemData = {
    ecommerce: {
      currency: useConfdata().currency,
      value: raw.price ?? 0,
      items: [translatedAccommodationToProductItem(raw)],
    },
  }
  useTracking().send([
    { ecommerce: null },
    {
      event: 'add_to_cart',
      ...data,
    },
  ])
}

function resultDocumentCategory(doc: ResultDocument) {
  const type = typeMapping[doc.type as Type]
  const detailType = detailTypeMapping[doc.buildingtype as DetailType]
  const country = seoify(doc.country)
  const region = seoify(doc.region)
  const place = seoify(doc.place)
  return `${country}/${region}/${place}/${type}/${detailType}`
}

function addToWishlist(
  raw: ResultDocument[] | TranslatedAccommodationWithPrice[],
): void {
  const isResultDocument = (raw[0] as unknown as ResultDocument).trip?.price
  const data: ViewItemData = {
    ecommerce: {
      currency: useConfdata().currency,
      value:
        (raw[0] as TranslatedAccommodationWithPrice)?.price ||
        (raw[0] as ResultDocument).trip?.price ||
        0,
      items: isResultDocument
        ? resultDocumentsToProductItems(raw as ResultDocument[])
        : [
            translatedAccommodationToProductItem(
              raw[0] as TranslatedAccommodationWithPrice,
            ),
          ],
    },
  }
  delete data.ecommerce.items[0].discount
  delete data.ecommerce.items[0].pool
  useTracking().send([
    { ecommerce: null },
    {
      event: 'add_to_wishlist',
      ...data,
    },
  ])
}

function removeFromWishlist(
  raw: ResultDocument[] | TranslatedAccommodationWithPrice[],
): void {
  const isResultDocument = (raw[0] as unknown as ResultDocument).trip?.price
  const data: ViewItemData = {
    ecommerce: {
      currency: useConfdata().currency,
      value:
        (raw[0] as TranslatedAccommodationWithPrice)?.price ||
        (raw[0] as ResultDocument).trip?.price ||
        0,
      items: isResultDocument
        ? resultDocumentsToProductItems(raw as ResultDocument[])
        : [
            translatedAccommodationToProductItem(
              raw[0] as TranslatedAccommodationWithPrice,
            ),
          ],
    },
  }
  delete data.ecommerce.items[0].discount
  delete data.ecommerce.items[0].pool
  useTracking().send([
    { ecommerce: null },
    {
      event: 'remove_from_wishlist',
      ...data,
    },
  ])
}

function selectItem(raw: ResultDocument, listId = ''): void {
  const data: ViewItemListData = {
    ecommerce: {
      item_list_id: listId,
      item_list_name: trim(location.pathname, '/'),
      items: resultDocumentsToProductItems([raw]),
    },
  }
  useTracking().send([
    { ecommerce: null },
    {
      event: 'select_item',
      ...data,
    },
  ])
}

function selectPromotion(data: ProductPromotion): void {
  useTracking().send([
    { ecommerce: null },
    {
      event: 'select_promotion',
      ecommerce: data,
    },
  ])
}

function viewItem(raw: TranslatedAccommodationWithPrice): void {
  const type = typeMapping[raw.type.code as Type]
  const detailType = detailTypeMapping[raw.type.detail as DetailType]

  const data: ViewItemData = {
    ecommerce: {
      currency: useConfdata().currency,
      value: raw.regularPrice ?? 0,
      items: [
        {
          item_id: raw.code,
          item_name: raw.name,
          item_brand: `${type}/${detailType}`,
          item_category: accomodationCategory(raw),
          price: raw.price ?? 0,
          quantity: 1,
          item_end_date: raw.checkout && (toServerDate(raw.checkout) as string),
          item_start_date: raw.checkin && (toServerDate(raw.checkin) as string),
          item_quality: raw.evaluation.stars ?? 0,
          item_currency: useConfdata().currency,
          item_pax: raw.pax.toString(),
          pool: !!raw.attributes?.pool,
          discount: raw.reduction ?? 0,
        },
      ],
    },
  }

  useTracking().send([
    { ecommerce: null },
    {
      event: 'view_item',
      ...data,
    },
  ])
}

function viewItemList(
  documents: ResultDocument[],
  destination?: Partial<Destination>,
) {
  useTracking().send([
    { ecommerce: null },
    {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: '',
        item_list_name: trim(destination?.urlPath ?? location.pathname, '/'),
        items: resultDocumentsToProductItems(documents),
      },
    },
  ])
}

function viewPromotion(data: ProductPromotion): void {
  useTracking().send([
    { ecommerce: null },
    {
      event: 'view_promotion',
      ecommerce: data,
    },
  ])
}

/* Helpers */

function resultDocumentsToProductItems(raw: ResultDocument[]): ProductItem[] {
  return (raw || []).map((item) => {
    const effectivePrice = item.trip?.price ?? 0
    const basePrice = item.trip?.reduction
      ? (effectivePrice / (100 - item.trip?.reduction)) * 100
      : effectivePrice
    const discount = basePrice - effectivePrice
    return {
      item_id: item.code,
      item_brand: brand(item.type as Type),
      item_category: resultDocumentCategory(item), // @todo: ResultDocument type doesn't have fields required to fill this field
      item_currency: useConfdata().currency,
      item_end_date: item.trip?.checkout as string,
      item_name: item.name,
      item_pax: item.pax.toString(),
      item_quality: item.stars,
      item_regular_price: parseFloat(basePrice.toFixed(2)),
      item_start_date: item.trip?.checkin as string,
      price: parseFloat(effectivePrice.toFixed(2)),
      quantity: 1,
      discount: parseFloat(discount.toFixed(2)),
      rrr: raw,
    }
  })
}

function translatedAccommodationToProductItem(
  raw: TranslatedAccommodationWithPrice,
): ProductItem {
  const type = typeMapping[raw.type.code as Type]
  const detailType = detailTypeMapping[raw.type.detail as DetailType]

  return {
    item_id: raw.code,
    item_name: raw.name,
    item_brand: `${type}/${detailType}`,
    item_category: accomodationCategory(raw),
    price: raw.price ?? 0,
    quantity: 1,
    item_end_date: dateToString(raw.checkout),
    item_start_date: dateToString(raw.checkin),
    item_quality: raw.evaluation.stars ?? 0,
    item_regular_price: raw.regularPrice,
    item_currency: useConfdata().currency,
    item_pax: raw.pax.toString(),
    discount: raw.reduction ?? 0,
    pool: !!raw.attributes.pool,
  }
}

export default {
  addToCart,
  addToWishlist,
  removeFromWishlist,
  selectItem,
  selectPromotion,
  viewItem,
  viewItemList,
  viewPromotion,
}

<template>
  <main class="relative pb-8">
    <!-- MOBIL -->
    <section
      class="top-0 z-1 bg-bgr-50 md:hidden"
      :class="mapMode ? 'fixed inset-x-0 mx-auto' : 'sticky '"
    >
      <nav
        class="container-fluid flex items-center justify-between bg-bgr-50 py-2 pt-3 text-sm"
      >
        <button
          class="flex items-center rounded-full border-0 border-bgr-200 bg-bgr px-2 py-2 text-xs shadow ring-thm hover:border-transparent hover:ring-2 focus:border-transparent focus:outline-none focus:ring-2"
          @click="filterOpen = !filterOpen"
        >
          <WebccIcon name="searchpage/settings" class="mx-1 h-4 w-4 text-thm" />
          <span class="px-1 font-bold">{{ $t(`${trPrefix}filters`) }}</span>
        </button>
        <div class="flex items-center">
          <SortingDropdown
            :class="{ hidden: mapMode }"
            :model-value="filters.sorting"
            @update:model-value="onSort"
          />

          <div
            class="ml-2 flex items-center rounded-full border-0 border-bgr-200 bg-bgr px-2 py-2 text-xs shadow ring-thm hover:border-transparent hover:ring-2 focus:border-transparent focus:outline-none focus:ring-2"
            @click="mapMode = !mapMode"
          >
            <WebccIcon
              :name="mapMode ? 'searchpage/sorting' : 'content/map'"
              class="mr-1 h-4 w-4 text-thm"
            />
            <span class="px-1">{{
              $t(`${trPrefix}${mapMode ? 'list' : 'map'}`)
            }}</span>
          </div>
        </div>
      </nav>

      <div
        class="fixed inset-0 z-[30] bg-black/50 backdrop-blur-xs"
        :class="{ hidden: !filterOpen }"
        @click="filterOpen = false"
      ></div>
      <nav
        class="fixed left-0 top-0 z-[30] h-full w-72 transform bg-bgr-100 px-4 py-2 transition-all duration-300 ease-in-out"
        :class="[
          filterOpen ? 'translate-x-0' : '-translate-x-full',
          flexTrip.open ? '' : 'overflow-auto',
        ]"
      >
        <button
          v-if="!flexTrip.open"
          class="sticky left-72 top-0.5 z-[50] -mr-2 cursor-pointer rounded-full border-[12px] border-bgr-100 bg-bgr-400 text-txt-weakest hover:bg-bgr-600"
          @click="filterOpen = !filterOpen"
        >
          <WebccIcon name="site/x" class="h-6 w-6 stroke-2 p-0.5" />
        </button>
        <!-- TODO: -->
        <Filter
          class="relative -top-9"
          :filters="filters"
          :facets="facets"
          :countries="geo.countries"
          :regions="geo.regions"
          :places="geo.places"
        />
        <WebccButton
          variant="cta"
          size="md"
          class="relative -top-2 w-full"
          @click="filterOpen = false"
        >
          <span>{{ $t(`${trPrefix}heading`) }}</span>
        </WebccButton>
      </nav>
    </section>
    <!-- END MOBIL -->

    <!-- Filter Desktop & Suchbereich -->
    <main
      ref="scrollpoint"
      class="container-fluid-lg relative flex items-start md:gap-6"
    >
      <!-- TODO: -->
      <Filter
        :filters="filters"
        :facets="facets"
        :countries="geo.countries"
        :regions="geo.regions"
        :places="geo.places"
        class="mt-4 hidden pt-1 md:block"
      />

      <section class="sticky top-4 w-full md:mt-4">
        <nav
          class="relative flex flex-col flex-wrap items-end justify-between pb-4 sm:flex-row sm:flex-nowrap"
        >
          <WebccHeading
            v-if="total"
            :title="title"
            :level="2"
            size="base"
            space="mr-auto"
          />
          <div
            v-if="total"
            class="sticky top-4 ml-auto hidden w-full items-center justify-end sm:w-auto md:flex"
          >
            <SortingDropdown
              :class="{ hidden: mapMode }"
              :model-value="filters.sorting"
              @update:model-value="onSort"
            />
            <div
              class="ml-2 flex items-center rounded-full border-0 border-bgr-200 bg-bgr px-2 py-2 text-xs shadow ring-thm hover:border-transparent hover:ring-2 focus:border-transparent focus:outline-none focus:ring-2"
            >
              <WebccSwitch
                v-model="mapMode"
                :label-left="$t(`${trPrefix}map`) + ''"
              />
            </div>
          </div>
        </nav>

        <main class="relative">
          <div v-if="loading" class="absolute h-full w-full">
            <div
              class="sticky top-0 h-full max-h-screen min-h-356 w-full"
              :class="[
                !total
                  ? 'bg-gradient-to-b from-bgr-200 to-bgr-50'
                  : 'z-[15] bg-black/10 backdrop-blur',
              ]"
            >
              <div
                v-if="!$params.iframe && !total"
                class="flex flex-col py-8 w-full items-center justify-center"
              >
                <NuxtImg
                  class="h-14 w-full opacity-50 grayscale"
                  :src="`logos/interhome.svg`"
                />
              </div>
              <WebccLoaderOverlay
                absolute
                :opacity="0"
                class="left-0 mx-auto flex h-full w-full flex-col items-start justify-start"
              />
            </div>
          </div>

          <SearchAreaNoResult v-if="!total && !loading" />

          <section
            v-if="!total && loading"
            class="w-full rounded bg-bgr p-4 pb-12"
          >
            <!-- inital search without old result -->
          </section>

          <template v-else>
            <!-- List section -->
            <section
              v-if="total && !mapMode"
              class="flex w-full flex-col justify-start gap-4"
            >
              <template
                v-for="(doc, i) in docs"
                :key="JSON.stringify({ code: doc.code, trip: doc.trip })"
              >
                <HitboxOrig
                  v-track:impression="{ event: 'search:objectImpression' }"
                  class="static hidden md:grid"
                  :accom="doc"
                  :checkin="checkin"
                  :checkout="checkout"
                  :pax="pax"
                  @interaction="emit('interaction', $event)"
                />
                <!-- Trustpilot oder Newsletter-Signup > md-->
                <template v-if="!$params.iframe">
                  <div
                    v-if="
                      i === 2 || (docs!.length < 3 && i === docs!.length - 1)
                    "
                    :key="i"
                    class="hidden md:block"
                  >
                    <slot></slot>
                  </div>
                </template>
              </template>

              <div class="flex flex-col gap-4 sm:grid sm:grid-cols-2 md:hidden">
                <template
                  v-for="(doc, i) in docs"
                  :key="JSON.stringify({ code: doc.code, trip: doc.trip })"
                >
                  <HitboxCard
                    v-track:impression="{ event: 'search:objectImpression' }"
                    class="static"
                    :accom="doc"
                    :checkin="checkin"
                    :checkout="checkout"
                    :pax="pax"
                    @to-detailpage="onToDetailpage(doc)"
                  />
                  <!-- Trustpilot oder Newsletter-Signup < md -->
                  <template v-if="!$params.iframe">
                    <slot
                      v-if="
                        i === 1 || (docs!.length < 2 && i === docs!.length - 1)
                      "
                    ></slot
                  ></template>
                </template>
              </div>

              <WebccPagination
                v-if="pagesTotal > 1"
                class="mb-16"
                :active="pageActive"
                :total="pagesTotal"
                :unique-key="JSON.stringify(search.filters)"
                @select-page="loadPage"
              />
            </section>

            <!-- Map section -->
            <section
              v-if="total && mapMode"
              class="fixed inset-0 top-12 z-[50] overflow-hidden rounded shadow md:[position:initial]"
            >
              <div
                class="bg-bgr p-2"
                :class="
                  settings.mapShowMapInfo || settings.mapShowProposal
                    ? 'block'
                    : 'hidden'
                "
              >
                <div v-if="settings.mapShowMapInfo" class="mb-1 text-sm">
                  Zoomstufe Map: {{ testmapInfo.zoomMap }}, AutoMaxZoom Map:
                  {{ testmapInfo.autoMaxZoom }}, deClusterLevel:
                  {{ testmapInfo.deClusterLevel }}, Anzahl Marker:
                  {{ testmapInfo.itemsLength }}
                </div>
                <div class="text-sm">
                  <WebccButton
                    size="sm"
                    :label="destProposalString"
                    @click="setDestination"
                  />
                  Vorschlag Destination
                </div>
              </div>
              <MapSearch
                class="h-full w-full md:h-[48rem]"
                @update-info="testmapInfo = $event"
                @update-dest="setDestUpdate"
              />
            </section>
          </template>
        </main>
      </section>
    </main>
  </main>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.views.search.area.'

const emit = defineEmits<{
  'toggle-bookmark': [string?]
  interaction: [
    { bookmark?: { accom: ResultDocument; state: boolean }; map?: boolean },
  ]
}>()

const confData = useConfdata()
const settings = useSettings()
const search = useSearch()
const flexTrip = useFlexTrip()
const tracking = useTracking()
const screen = useScreen()

const mapMode = ref(false)
const filterOpen = ref(false)
const destProposalString = ref('')
const destProposal = ref({ country: '', region: '', place: '' })
const testmapInfo = ref<MapUpdates>({
  zoomMap: 0,
  autoMaxZoom: 0,
  deClusterLevel: 0,
  itemsLength: 0,
})

const scrollpoint = ref<HTMLDivElement>()

const {
  docs,
  total,
  totalPages,
  filters,
  facets,
  geoAll: geo,
  geoCurrent,
  loading,
} = storeToRefs(search)

const checkin = computed(() => tryParseDate(filters.value.checkin))
const checkout = computed(() => tryParseDate(filters.value.checkout))
const pax = computed(() => tryParseInt(filters.value.pax?.toString()) ?? 1)
const destination = computed(
  () =>
    geoCurrent.value.place ??
    geoCurrent.value.region ??
    geoCurrent.value.country,
)
const h2 = computed(() => (confData.additionalBody as SearchPageBody).h2)
const title = computed(() =>
  replacePlaceholders(h2.value, {
    amount: total.value,
    destination: destination.value?.name,
  }),
)
const pageActive = computed(
  () => tryParseInt(filters.value.page?.toString()) ?? 1,
)

const pagesTotal = computed(() => totalPages.value ?? 0)

watch(() => screen.current, updateHeight)
watch(mapMode, updateHeight)
watch(mapMode, (value) => tracking.handlers?.search.generalMap(value))

function updateHeight() {
  const [overflow, height] =
    mapMode.value && screen.isSmall ? ['hidden', '100vh'] : ['auto', 'auto']
  document.body.style.overflow = overflow
  document.body.style.height = height
}

async function onSort(sorting: SortingOptions = 'recommendation') {
  await search.setFilter({ sorting })
  tracking.handlers?.search.sorting(sorting)
}

async function loadPage(page: number) {
  tracking.handlers?.search.paging({ current: page, total: pagesTotal.value })
  await search.setFilter({ page })
  scrollpoint.value?.scrollIntoView({ behavior: 'smooth' })
}

function setDestUpdate(value: {
  country: { name: string; code: string }
  region: { name: string; code: string }
  place: { name: string; code: string }
}) {
  destProposalString.value =
    [value.country.name, value.region.name, value.place.name]
      .filter(Boolean)
      .join(' > ') || ''
  destProposal.value = {
    country: value.country.code,
    region: value.region.code,
    place: value.place.code,
  }
}

async function setDestination() {
  await search.setFilter({})
}

function onToDetailpage(doc: ResultDocument) {
  tracking.handlers?.ecommerce.selectItem(doc)
  tracking.handlers?.eec.productClick(doc)
}
</script>

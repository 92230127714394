<template>
  <section
    v-if="enabled"
    v-track:impression="'search:topNaturalSightsImpression'"
    class="container-fluid-lg mb-14"
  >
    <header class="mb-8 flex justify-between space-x-3">
      <WebccHeading
        :title="title"
        :level="2"
        :space="'mt-0'"
        class="text-txt-stronger font-medium"
      />
      <WebccLink
        target="_blank"
        anchor
        :to="link.url"
        @click.prevent="onReadMoreClick"
      >
        <WebccButton outline variant="theme">{{ link.title }}</WebccButton>
      </WebccLink>
    </header>
    <p class="text-base">{{ text }}</p>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: NaturalSightsInfo
}>()

const { title, text, link } = props.modelValue

const enabled = useFlag('top-natural-sights')

/**
 * Handles external link clicks to ensure that URLs with anchors open correctly.
 *
 * Google Analytics may add the `_ga` param to the URL with anchor, making it invalid:
 * https://www.interhome.de/reisetipps/reiseziele/italien/toskana#toc-natural&_ga=2.40710050.1060525578.1706113349-1931037659.1706113349
 */
function onReadMoreClick() {
  window.open(link.url, '_blank')
  useTracking().handlers?.search.topNaturalSightsRequest()
}
</script>

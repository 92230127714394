import { pick, pickBy, fromPairs, sortBy, toPairs, isObject } from 'lodash-es'

export type Falsy = false | null | undefined | 0 | 0n | ''

export function objectsEqual(a: object, b: object) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export function getAnyOf<
  Obj extends Record<string, unknown>,
  Keys extends (keyof Obj)[] | string[] | readonly string[],
>(
  obj: Obj,
  keys: Keys,
): Keys extends (keyof Obj)[] ? Obj[Keys[number]] : unknown {
  return Object.values(pick(obj, keys))[0]
}

export function removeFalsy<
  T extends Record<string, unknown>,
  R extends {
    [K in keyof T as T[K] extends Falsy ? never : K]: Exclude<T[K], Falsy>
  },
>(source: T): R {
  return pickBy(source, Boolean) as R
}

export function sortKeys<T extends object>(unsorted: T): T {
  if (!isObject(unsorted)) return unsorted
  return fromPairs(sortBy(toPairs(unsorted), [0])) as T
}

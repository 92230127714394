<template>
  <section>
    <span
      class="py-1.5 px-2.5 rounded-lg rounded-bl-none bg-thm text-thm-contrast font-bold"
    >
      {{ rating.text }}</span
    >
    <span class="ml-1 leading-6"
      >({{ $t(`${trPrefix}review_s`, accom.reviews) }})</span
    >
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Rating.'

const props = defineProps<{
  accom: ResultDocument
}>()

const rating = useRating(() => props.accom.rating)
</script>

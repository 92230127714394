<template>
  <WebccDropdown v-model="sorting" :options="options" :mode="mode">
    <template #button="{ details: label }">
      <div class="p-3 overflow-hidden text-ellipsis whitespace-nowrap">
        {{ $t(`${trPrefix}prefix`) }} {{ label }}
      </div>
    </template>
  </WebccDropdown>
</template>

<script setup lang="ts">
import type { Mode } from '~/components/@ihgroup/components/WebccDropdown.vue'

withDefaults(defineProps<{ mode?: Mode }>(), { mode: 'fit' })

const trPrefix = 'www.components.views.search.area.sorting.'

const { t } = useI18n()

const options = computed(() => ({
  recommendation: t(`${trPrefix}recommendation`),
  '-lastbookday': t(`${trPrefix}bestsellers`),
  age: t(`${trPrefix}age`),
  '-averagerating': t(`${trPrefix}rating`),
  price: t(`${trPrefix}priceAsc`),
  '-price': t(`${trPrefix}priceDesc`),
  pax: t(`${trPrefix}paxAsc`),
  '-pax': t(`${trPrefix}paxDesc`),
}))

const model = defineModel<SortParams>({ required: true })

const sorting = computed({
  get: () => model.value.sorting ?? SORT_DEFAULT,
  set: (value: SortType) =>
    (model.value = { sorting: value === SORT_DEFAULT ? undefined : value }),
})
</script>

const config: AB[] = [
  {
    key: 'dp-quicksearch',
    name: 'Hide Autosuggest on Detailpage',
    default: false,
    description: 'Ausblenden der Quicksearch auf der Objektdetailseite',
    domains: [''],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  // {
  //   key: 'filter',
  //   name: 'filter',
  //   default: false,
  //   description: 'Experimentelle, neue Filtereinstellungen',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  {
    key: 'newsearchUI',
    name: 'newsearchUI',
    default: false,
    description: 'Neues Design Suche',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  // {
  //   key: 'hitboxes',
  //   name: 'Hitboxes variants',
  //   default: false,
  //   description: 'Verschiedene Varianten kleiner Hitboxen',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  {
    key: 'vacancypopover',
    name: 'vacancypopover',
    default: false,
    description: 'Anzeige des Vakanzkalenders als Popover auf Detailseite',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  {
    key: 'legalinfo',
    name: 'legalinfo',
    default: false,
    description:
      'Anzeige der Nebenkosteninformation auf der mobilen Suchseite. false: Als Icon am Preis, true: Als Text am unteren Ende',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  {
    key: 'keepRedirectParams',
    name: 'keepRedirectParams',
    default: true,
    description:
      'Verhindert den Verlust der redirect-params im mounted-lifecycle-event',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  // {
  //   key: 'separateSeoAndSearch',
  //   name: 'separateSeoAndSearch',
  //   default: true,
  //   description: 'Verschiebt einige SEO-relevante Elemente auf der Suchseite aus dem effektiven Suchbereich',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  // {
  //   key: 'chatbot',
  //   name: 'chatbot',
  //   default: true,
  //   description: 'Aktiviert die Chatbot-Integration auf der FAQ-Seite',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  // {
  //   key: 'dpTooltip',
  //   name: 'dpTooltip',
  //   default: false,
  //   description: 'Zeigt statt des Blocks unter dem Datepicker einen Tooltip beim Hovern über einem Tag an.',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  {
    key: 'monthlyPrices',
    name: 'monthlyPrices',
    default: false,
    description:
      'Zeigt auf der Detailseite über dem Datepicker eine Übersicht der Monatspreise an.',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  {
    key: 'objectteaser',
    name: 'Object Teasers',
    default: true,
    description: 'Display object teasers on search page',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  // {
  //   key: 'mapSearchDebug',
  //   name: 'Debug MapSearch',
  //   default: false,
  //   description: 'Add debugging and testing controls to MapSearch',
  //   domains: [],
  //   devices: {
  //     mobile: true,
  //     desktop: true,
  //   },
  //   variants: {
  //     false: 1,
  //     true: 1,
  //   },
  // },
  {
    key: 'chatGPT_PDP',
    name: 'Descriptions Derived from AI',
    default: false,
    description: 'Show AI heading and description on detailpage',
    domains: ['www.interhome.de', 'www.interhome.at', 'www.interhome.ch'],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      true: 1,
    },
  },
  {
    key: 'dialogInterruptedBooking',
    name: 'Teaser to continue booking',
    default: true,
    description: 'Show teaser to continue interrupted booking',
    domains: [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  {
    key: 'userSurveyJotform',
    name: 'User Survey Jotform',
    default: false,
    description: 'Show user survey modal on detail page',
    domains: ['www.interchalet.de'], // TODO Remove this ab test if was only on Interchalet
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      false: 1,
      true: 1,
    },
  },
  {
    key: 'guestWorld',
    name: 'Guest World Integration',
    default: false,
    description: 'Enable integration with new Guest World app',
    domains:
      process.env.NUXT_AB_GUEST_WORLD_DOMAINS?.split(',').map(
        (domain) => domain.trim() as Domain,
      ) ?? [],
    devices: {
      mobile: true,
      desktop: true,
    },
    variants: {
      true: 1,
    },
  },
]

export default config

<template>
  <section class="truncate text-ctr">
    <template v-for="(count, key) in features" :key="key">
      <span>{{ $t(`${trPrefix}${key}`, count) }}</span>
      <span class="last:hidden font-bold mx-2">&bull;</span>
    </template>
  </section>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Features.'

const props = defineProps<{
  accom: ResultDocument
}>()

const features = computed(() => ({
  pax_s: props.accom.pax,
  room_s: props.accom.rooms,
  bedroom_s: props.accom.bedrooms,
  bathroom_s: props.accom.bathrooms,
}))
</script>

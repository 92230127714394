export function tryParseInt(value?: string): number | undefined {
  const result = value ? parseInt(value) : NaN
  return !isNaN(result) ? result : undefined
}

export function tryParseFloat<
  Value extends string | number | undefined | null,
  Result extends [Value] extends [number] ? number : number | undefined,
>(value?: Value): Result {
  const result =
    typeof value === 'number' ? value : value ? parseFloat(String(value)) : NaN
  return (!isNaN(result) ? result : undefined) as Result
}

export function range(from: number = 1, to: number): number[] {
  from = Math.max(1, from)
  return Array.from({ length: to - from + 1 }, (_, i) => i + from)
}

export function positiveMod(value: number, modulus: number): number {
  return ((value % modulus) + modulus) % modulus
}

import { toast, type Content as ToastContent } from 'vue3-toastify'

const TOAST_DURATION_DEFAULT = 4000

export default function useToaster() {
  function show(
    type: ToastType,
    content: ToastContent,
    options?: ToastOptions,
  ) {
    return toast(content, {
      type,
      delay: options?.delay,
      autoClose: options?.duration ?? TOAST_DURATION_DEFAULT,
      toastClassName: `close-button-${options?.closeButton ?? 'center'}`,
    })
  }

  function error(content: ToastContent, options?: ToastOptions) {
    return show('error', content, options)
  }

  function warning(content: ToastContent, options?: ToastOptions) {
    return show('warning', content, options)
  }

  function success(content: ToastContent, options?: ToastOptions) {
    return show('success', content, options)
  }

  function info(content: ToastContent, options?: ToastOptions) {
    return show('info', content, options)
  }

  function untyped(content: ToastContent, options?: ToastOptions) {
    return show('default', content, options)
  }

  return { show, error, warning, success, info, untyped, raw: toast }
}

import mapValues from 'lodash-es/mapValues'
import toString from 'lodash-es/toString'

export interface DateParams {
  checkin: string
  checkout: string
}

export interface FlexDateParams extends DateParams {
  duration: number
}

export const DESTINATION_PARAMS = Object.freeze({
  country: true,
  region: true,
  place: true,
})

export const DATE_PARAMS = Object.freeze({
  checkin: true,
  checkout: true,
  duration: true,
})

export const PAX_PARAMS = Object.freeze({
  pax: true,
})

export const ROOM_PARAMS = Object.freeze({
  bedrooms: true,
  bathrooms: true,
})

export const NUMBER_PARAMS = Object.freeze({
  ...PAX_PARAMS,
  ...ROOM_PARAMS,
})

export const ACCOMMODATION_TYPE_PARAMS = Object.freeze({
  house: true,
  apartment: true,
  detached: true,
  villa: true,
  chalet: true,
  farmhouse: true,
  studio: true,
})

export const SPECIAL_PARAMS = Object.freeze({
  casa: true,
  swiss_peak: true,
  workation: true,
  sustainable: true,
})

export const STARS_PARAMS = Object.freeze({
  stars: true,
})

export const POOL_PARAMS = Object.freeze({
  pool: true,
  pool_children: true,
  pool_indoor: true,
  pool_private: true,
})

export const PETS_PARAMS = Object.freeze({
  pets: true,
})

export const DISTANCE_PARAMS = Object.freeze({
  sea: true,
  lake: true,
  ski: true,
  center: true,
})

export const FEATURE_PARAMS = Object.freeze({
  wlan: true,
  aircondition: true,
  parking: true,
  garage: true,
  'balcony-or-terrace': true,
  dishwasher: true,
  washingmachine: true,
  tv: true,
  sea_or_lake_view: true,
  bbq: true,
  boat: true,
  cots: true,
  hottub: true,
  fireplace: true,
  sauna: true,
  // wheelchair: true,
  charging_station: true,
})

export const OFFER_PARAMS = Object.freeze({
  last_minute: true,
  special_offer: true,
  'discount-20': true,
  cheapcheap: true,
  early_booker: true,
})

export const PRICE_PARAMS = Object.freeze({
  'min-price': true,
  maxPrice: true,
})

export const HOLIDAY_TYPE_PARAMS = Object.freeze({
  familyfriendly: true,
  holiday_resort: true,
  residence: true,
})

export const ACTIVITY_PARAMS = Object.freeze({
  citytrips: true,
  utoring: true,
  fishing: true,
  fishing_certified: true,
  skiing: true,
  hiking: true,
  golfing: true,
  cycling: true,
  wellness: true,
  tennis: true,
  surfing: true,
  sailing: true,
  mountainbiking: true,
  riding: true,
  crosscountryskiing: true,
})

export const TEXT_PARAMS = Object.freeze({
  q: true,
})

export const GEO_COORD_PARAMS = Object.freeze({
  lat: true,
  lon: true,
  radius: true,

  name: true,
  heading: true,
})

export const SEASON_PARAMS = Object.freeze({
  season: true,
})

export const PAGE_PARAMS = Object.freeze({
  page: true,
})

export const SORT_PARAMS = Object.freeze<SortParams>({
  sorting: undefined,
})

export const ALLOWED_SEARCH_PARAMS = Object.freeze({
  ...DESTINATION_PARAMS,
  ...DATE_PARAMS,
  ...PAX_PARAMS,
  ...ROOM_PARAMS,
  ...ACCOMMODATION_TYPE_PARAMS,
  ...SPECIAL_PARAMS,
  ...STARS_PARAMS,
  ...POOL_PARAMS,
  ...PETS_PARAMS,
  ...DISTANCE_PARAMS,
  ...FEATURE_PARAMS,
  ...OFFER_PARAMS,
  ...PRICE_PARAMS,
  ...HOLIDAY_TYPE_PARAMS,
  ...ACTIVITY_PARAMS,
  ...TEXT_PARAMS,
  ...GEO_COORD_PARAMS,
  ...SEASON_PARAMS,
  ...PAGE_PARAMS,
  ...SORT_PARAMS,
})

export const ALLOWED_QUERY_PARAMS = Object.freeze({
  ...ALLOWED_SEARCH_PARAMS,
  fuzzyness: true,
  signedIn: true,
})

export function unpack(obj: object): PlainParams {
  const result: PlainParams = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(result, unpack(value))
    } else if (value) {
      result[key] = value
    }
  })

  return result
}

export function asQueryParams<T extends object>(
  source: T,
): Record<string, string> {
  return mapValues(source, toString)
}

export function hasDateParams(
  params: Partial<DateParams>,
): params is DateParams {
  return !!(params.checkin && params.checkout)
}

export function addDatesFuzziness(
  { checkin, checkout }: DateParams,
  fuzziness: number,
): FlexDateParams {
  return {
    checkin: toDate(daysSub(checkin, fuzziness)),
    checkout: toDate(daysAdd(checkout, fuzziness)),
    duration: calculateDuration(checkin, checkout),
  }
}

export const SORT_DEFAULT = 'recommendation'

export type SortType = Exclude<SortingOptions, '-scoresort'>

export interface SortParams {
  sorting?: SortType
}

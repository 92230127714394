export const useSearchStore = defineStore(
  'search',
  (): {
    docs: Ref<ResultDocument[] | undefined>
    loading: Ref<boolean>
    facets: Ref<Facets | undefined>
    geo: Ref<{
      countries?: DestFacet[]
      regions?: DestFacet[]
      places?: DestFacet[]
    }>
    geoAll: Ref<{
      countries: DestFacet[]
      regions: DestFacet[]
      places: DestFacet[]
    }>
    geoCurrent: Ref<{
      country?: DestFacet
      region?: DestFacet
      place?: DestFacet
    }>
    filters: Ref<Params>
    trip: Ref<Params>
    preventSearch: Ref<boolean>
    total: Ref<number>
    totalPages: Ref<number>
    viewport: Ref<Viewport | undefined>
    url: Ref<string>
    search: () => Promise<void>
    setFilter: (f: Params, options?: { reset?: boolean }) => Promise<void>
  } => {
    const facets = ref<Facets>()
    const viewport = ref<Viewport>()
    const total = ref<number>(0)
    const totalPages = ref<number>(0)
    const docs = ref<ResultDocument[]>()
    const geo = ref<{
      countries?: DestFacet[]
      regions?: DestFacet[]
      places?: DestFacet[]
    }>({})
    const filters = ref<Params>({})
    const preventSearch = ref(false)
    const loading = ref(true)
    const loadingFilters = ref(true)
    const allRegions = ref<DestFacet[]>([])
    const allPlaces = ref<DestFacet[]>([])
    const lastChanged = ref<string[]>([])

    const isRedesign = useFlag('srp-redesign')

    const pagesizeDefault = computed(() => (isRedesign.value ? 18 : 20))

    const trip = computed(() => {
      const { checkin, checkout, duration, pax } = filters.value
      return { checkin, checkout, duration, pax }
    })

    const geoAll = computed(() => {
      return {
        countries: geo.value.countries ?? [],
        regions: allRegions.value ?? [],
        places: allPlaces.value ?? [],
      }
    })

    const geoCurrent = computed(() => {
      const { country, region, place } = filters.value
      return {
        country: country
          ? geoAll.value.countries.find(geoByCode(country))
          : undefined,
        region: region
          ? geoAll.value.regions.find(geoByCode(region))
          : undefined,
        place: place ? geoAll.value.places.find(geoByCode(place)) : undefined,
      }
    })

    const url = computed(() => {
      // Waiting for countries, regions, and places to load to form the URL
      if (
        loadingFilters.value ||
        (!geo.value.countries?.length && loading.value)
      )
        return ''
      const paramsFromFilters = {
        ...removeFalsy(filters.value),
        fuzzyness: useFlexTrip().fuzzyness,
      }

      if (!useFlexTrip().fuzzyness) {
        paramsFromFilters.duration = filters.value.duration
      }

      return useURLs()
        .buildSearchUrl(paramsFromFilters, geoCurrent.value)
        .toString()
    })

    async function search() {
      await Promise.all([_fetchAccommodations(), _fetchRegionsAndPlaces()])
    }

    async function setFilter(params: Params, options?: { reset?: boolean }) {
      if (params.fuzzyness) {
        useFlexTrip().fuzzyness = params.fuzzyness
      }
      const updates = Object.fromEntries(
        Object.entries(params).filter(([key]) => key in ALLOWED_SEARCH_PARAMS),
      )

      filters.value = { ...(options?.reset ? {} : filters.value), ...updates }

      if (!updates.page) {
        delete filters.value.page
      }

      if (preventSearch.value) return

      lastChanged.value = Object.keys(updates)
      if (lastChanged.value.length && !options?.reset) {
        useTracking().handlers?.facette.filter(
          updates,
          filters.value,
          facets.value,
        )
      }
      await search()
    }

    async function _fetchAccommodations() {
      preventSearch.value = true
      loading.value = true

      const { currency } = useConfdata()
      const season = filters.value.season || useSeason(filters.value.checkin)
      const { data } = await _search({ ...filters.value, season, currency })

      total.value = data.value?.totalHits ?? 0
      totalPages.value = data.value?.totalPages ?? 0
      facets.value = data.value?.facets
      docs.value = data.value?.docs
      geo.value.countries = data.value?.facets?.countries
      geo.value.regions = data.value?.facets?.regions
      geo.value.places = data.value?.facets?.places
      viewport.value = data.value?.viewport
      useTracking().handlers?.search.results(
        useConfdata().destination,
        filters.value,
        total.value,
      )
      useTracking().handlers?.search.resultCount(total.value)
      nextTick(() => {
        useTracking().handlers?.ecommerce.viewItemList(data.value?.docs ?? [])
        useTracking().handlers?.eec.productImpression(data.value?.docs ?? [])
      })

      preventSearch.value = false
      loading.value = false
    }

    async function _fetchRegionsAndPlaces() {
      loadingFilters.value = true

      const { country, region } = filters.value

      if (!lastChanged.value.length || lastChanged.value.includes('country')) {
        if (country) {
          const { data } = await _search({
            pagesize: -1,
            country,
            facet: ['regions', 'places'],
          })
          allRegions.value = data.value?.facets?.regions ?? []
          allPlaces.value = data.value?.facets?.places ?? []
        } else {
          allRegions.value = []
          allPlaces.value = []
        }
      }

      if (!lastChanged.value.length || lastChanged.value.includes('region')) {
        if (country && region) {
          const { data } = await _search({
            pagesize: -1,
            country,
            region,
            facet: ['places'],
          })
          allPlaces.value = data.value?.facets?.places ?? []
        } else {
          allPlaces.value = []
        }
      }

      loadingFilters.value = false
    }

    async function _search({ sorting, pagesize, ...params }: Params) {
      const { fuzzyness } = useFlexTrip()

      return await useSearchApi().search({
        ...params,
        salesoffice: useConfdata().salesoffice?.code,
        language: useL10N().language,
        ...(hasDateParams(params) && !params.duration && fuzzyness
          ? addDatesFuzziness(params, fuzzyness)
          : {}),
        sorting: sorting ?? '-scoresort',
        pagesize: pagesize ?? pagesizeDefault.value,
      })
    }

    return {
      facets,
      docs,
      loading,
      geo,
      geoAll,
      geoCurrent,
      viewport,
      filters,
      trip,
      preventSearch,
      total,
      totalPages,
      url,
      search,
      setFilter,
    }
  },
)

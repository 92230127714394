<template>
  <div class="group">
    <div class="relative h-full" :class="containerClasses">
      <div
        ref="container"
        class="keen-slider absolute flex bg-bgr h-full"
        @click="onClick"
      >
        <div
          v-for="({ id, loaded, alt, title, src }, index) in slides"
          :key="id"
          class="keen-slider__slide lazy__slide relative h-full min-w-full"
        >
          <img
            :src="loaded ? src : ''"
            :alt="alt"
            :title="title"
            :class="[
              coverFull ? 'w-full' : 'w-[inherit]',
              imageHeight ? `h-[${imageHeight}]` : 'h-full',
            ]"
            class="m-auto object-cover"
          />
          <div
            class="absolute inset-0 pointer-events-none *:pointer-events-auto"
          >
            <slot name="overlay" :index="index" />
          </div>
        </div>
      </div>
      <div
        v-for="direction in SLIDER_BUTTONS"
        :key="direction"
        class="absolute top-0 h-full"
        :class="`${direction}-0`"
      >
        <slot
          v-if="isMoreThenOneSlide"
          :name="`${direction}-button`"
          :click="() => onSlideButtonClick(direction)"
        >
          <button
            class="h-full"
            :class="direction === 'left' ? 'pl-4' : 'pr-4'"
            @click.stop="onSlideButtonClick(direction)"
            @mouseover="onSlideChangeIntent(direction)"
          >
            <span
              class="block p-1 rounded-lg bg-bgr bg-opacity-80 text-txt backdrop-blur-md transition-all opacity-0 duration-200 hover:scale-105 hover:bg-opacity-90 hover:shadow-md active:scale-100 group-hover:opacity-100"
            >
              <WebccIcon
                :name="`site/chevron-${direction}`"
                class="h-6 w-6 text-txt"
                filled
              />
            </span>
          </button>
        </slot>
      </div>
      <div class="absolute inset-0 pointer-events-none *:pointer-events-auto">
        <slot name="container-overlay" />
      </div>
    </div>
    <div
      v-if="isMoreThenOneSlide && dots !== 'none'"
      class="flex justify-center gap-2"
      :class="dots === 'internal' ? internalDotsLayout : externalDotsLayout"
    >
      <span
        v-for="index in paginationDots"
        :key="index"
        class="w-2 h-2 rounded-full transition-colors duration-300"
        :class="current === index ? dotsStyle.current : dotsStyle.default"
        tabindex="0"
        role="button"
        @click.stop="slideTo(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/vue.es'

interface Slide extends MediaImage {
  loaded?: boolean
}

type DotsDisplayMode = 'external' | 'internal' | 'none'
const internalDotsLayout = 'absolute bottom-2 w-full items-center'
const externalDotsLayout = 'flex-row align-middle p-4'

const dotsStyle = computed(() => {
  return props.dots === 'internal'
    ? { current: 'bg-bgr opacity-100 w-3 h-3', default: 'bg-bgr opacity-60' }
    : { current: 'bg-gray-700', default: 'bg-bgr' }
})

const props = withDefaults(
  defineProps<{
    images: MediaImage[]
    containerClasses?: string
    coverFull?: boolean
    initial?: number
    dots?: DotsDisplayMode
    imageHeight?: string
  }>(),
  {
    containerClasses: undefined,
    coverFull: false,
    initial: 0,
    dots: 'none',
    imageHeight: undefined,
  },
)

const SLIDER_BUTTONS = ['left', 'right'] as const

const emit = defineEmits<{
  click: [index: number]
  'slide-change': [index: number]
  'slide-change-intent': [direction: ScrollDirectionHorizontal]
  'slide-button-click': [direction: ScrollDirectionHorizontal]
}>()

defineSlots<{
  'overlay'(props: { index: number }): unknown
  'left-button'(props: { click(): void }): unknown
  'right-button'(props: { click(): void }): unknown
  'container-overlay'(): unknown
}>()

const current = ref(props.initial)
const slides = ref<Slide[]>([])

const isMoreThenOneSlide = computed(() => slides.value.length > 1)

setImages(props.images)

watch(() => props.images, setImages)

const [container, slider] = useKeenSlider({
  loop: true,
  initial: current.value,
  defaultAnimation: { duration: 800 },
  dragSpeed: 0.6,
  dragStarted({ track }) {
    onSlideChangeIntent(
      track.details.abs - current.value > 0 ? 'right' : 'left',
    )
  },
  slideChanged({ track }) {
    current.value = positiveMod(track.details.rel, slides.value.length)
    loadImagesAround(current.value)
    emit('slide-change', track.details.rel)
  },
})

function setImages(images: MediaImage[]) {
  slides.value = images.map((image) => ({ ...image }))
  loadImagesAround(current.value)
  nextTick(() => slider.value?.update())
}

/**
 * Loads the given and surrounding images for smooth sliding.
 */
function loadImagesAround(index: number) {
  ;[index - 1, index, index + 1].forEach(loadImageAt)
}

/**
 * Marks the image at the given index as `loaded` to trigger its actual load and rendering.
 */
function loadImageAt(index: number) {
  const relativeIndex = index % slides.value.length
  const image = slides.value.at(relativeIndex)
  if (image) image.loaded = true
}

onBeforeUnmount(() => {
  slider.value?.destroy()
})

function slideTo(index: number) {
  slider.value?.moveToIdx(index)
}

function onSlideButtonClick(direction: ScrollDirectionHorizontal) {
  const slideFn = direction === 'left' ? slider.value?.prev : slider.value?.next
  slideFn?.()
  emit('slide-button-click', direction)
}

function onSlideChangeIntent(direction: ScrollDirectionHorizontal) {
  emit('slide-change-intent', direction)
}

function onClick() {
  emit('click', current.value)
}

const maxDots = 5
const paginationDots = computed(() => {
  if (props.dots === 'external')
    return Array.from({ length: slides.value.length }, (_, i) => i)
  const total = slides.value.length
  const half = Math.ceil(maxDots / 2)

  if (current.value < half) {
    return Array.from({ length: Math.min(maxDots, total) }, (_, i) => i)
  }
  if (current.value > total - half) {
    return Array.from(
      { length: Math.min(maxDots, total) },
      (_, i) => total - maxDots + i,
    )
  }
  return Array.from({ length: maxDots }, (_, i) => current.value + 1 + i - half)
})
</script>

export default {
  displayed,
  addedToWishlist,
  removedFromWishlist,
  viewed,
  scrolled,
}

function createRecommendationEvent(
  action: string,
  language: string,
  data?: PreparedItem[],
): TrackingElement {
  return {
    event: 'universal_recommender',
    action,
    label: 'Universal Recommender',
    category: undefined,
    component: 'universal-recommender-home',
    position: undefined,
    language,
    items: data?.map((r) => r.accom),
    _clear: true,
  }
}

function displayed(language: string, data: PreparedItem[]) {
  useTracking().send(createRecommendationEvent('displayed', language, data))
}

function addedToWishlist(language: string, data: PreparedItem[]) {
  useTracking().send(
    createRecommendationEvent('add to wishlist', language, data),
  )
}

function removedFromWishlist(language: string, data: PreparedItem[]) {
  useTracking().send(
    createRecommendationEvent('remove from wishlist', language, data),
  )
}

function viewed(language: string, data: PreparedItem[]) {
  useTracking().send(
    createRecommendationEvent('view accommodation', language, data),
  )
}

function scrolled(
  direction: ScrollDirectionHorizontal,
  language: string,
  data: PreparedItem[],
) {
  const action = `${direction === 'left' ? 'previous' : 'next'} accommodation`
  useTracking().send(createRecommendationEvent(action, language, data))
}

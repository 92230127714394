<template>
  <NuxtErrorBoundary @error="console.error($event)">
    <div v-if="layoutAndComponent && !useRuntimeConfig().test">
      <!-- Layouts do not work properly in our dynamic routing setup. For now this isn't much of an issue since we never really used layouts anyway, so we can easily move the necessary components here directly. -->
      <template v-if="layoutAndComponent.layout === 'default'">
        <Colors :colors="useColors().colors" />
        <GeneralInfoBar />
        <B2BInfoBar />
        <Header v-if="showHeader" />
        <client-only>
          <Chatbot v-if="siteSettings?.includes('chatbot')" />
          <Notifications />
          <BookingTeaser />
          <BugHerd />
        </client-only>
        <NuxtErrorBoundary @error="console.error($event)">
          <component :is="layoutAndComponent.component" />
          <template #error="{ error, clearError }">
            <ErrorContent :error="error" :clear-error="clearError" />
          </template>
        </NuxtErrorBoundary>
        <Footer />
        <client-only>
          <DebugInfo />
        </client-only>
      </template>
      <template v-else-if="layoutAndComponent.layout === 'empty'">
        <component :is="layoutAndComponent.component" />
      </template>
      <Consent />
    </div>
    <template #error="{ error, clearError }">
      <ErrorFull :error="error" :clear-error="clearError" />
    </template>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import type { ConcreteComponent } from 'vue'
import type { ScreenSizePair } from '~/modules/screen/runtime/screen.types'

const params = useParams()
const confData = useConfdata()

const { siteSettings, pagetype, head } = storeToRefs(confData)

const layoutAndComponent = computed(
  (): {
    layout: 'default' | 'empty'
    component: ConcreteComponent | string
  } => {
    let layout: 'default' | 'empty'
    let component: ConcreteComponent | string
    switch (pagetype.value) {
      case 'start':
        component = resolveComponent('PageStart')
        layout = 'default'
        break
      case 'search':
        component = useFlag('srp-redesign').value
          ? resolveComponent('PageSearchRedesigned')
          : resolveComponent('PageSearch')
        layout = 'default'
        break
      case 'detail':
        component = resolveComponent('PageDetails')
        layout = 'default'
        break
      case 'content':
      case 'content-landing':
      case 'content-lso-overview':
      case 'content-lso-detail':
      case 'content-supporting':
        component = resolveComponent('PageContent')
        layout = 'default'
        break
      case 'reviews':
        component = resolveComponent('PageReviews')
        layout = 'default'
        break
      case 'catalog':
        component = resolveComponent('PageCatalogues')
        layout = 'empty'
        break
      case 'bookmarks':
        component = resolveComponent('PageBookmarks')
        layout = 'default'
        break
      case 'newsletter':
        component = resolveComponent('PageNewsletter')
        layout = 'default'
        break
      case 'emails':
        component = resolveComponent('PageEmails')
        layout = 'default'
        break
      case 'myrent':
        component = resolveComponent('PageMyrent')
        layout = 'empty'
        break
      case 'redirect':
        // If we reach this we are in huge trouble. Redirects must be handled a lot earlier since we will have incorrect data in useConfdata otherwise
        console.error('Routing with pagetype redirect. This must never happen!')
        component = resolveComponent('PageUnknown')
        layout = 'empty'
        break
      case 'unknown':
      default:
        switch (useRoute().path.split('/')[1]) {
          case 'taf':
            // Example: https://www.interhome.at/taf/?recommendationId=F2D993D001441EECB9FC0B9FAB9A4254
            component = resolveComponent('PageBookmarks')
            layout = 'default'
            navigateTo(
              `${confData.links?.bookmarks.href}?recommendationId=${useRoute().query.recommendationId}`,
              { redirectCode: 301 },
            )
            break
          case 'probes':
          case 'testing':
            component = resolveComponent(useRoute().path.split('/')[2])
            layout = 'empty'
            break
          default:
            if (import.meta.server) {
              setResponseStatus(useRequestEvent()!, 404)
            }
            component = resolveComponent('PageUnknown')
            layout = 'default'
        }
        break
    }

    return { component, layout }
  },
)

const showHeader = computed(() => {
  return (
    !params.iframe ||
    ['header', 'full'].includes(params.iframe!.layout.toString())
  )
})

const route = useRoute()

const routeChanged = () => {
  usePageHead().update(head.value)
}

watch(route, () => routeChanged(), { deep: true, immediate: true })

onBeforeMount(() => {
  if (useAB().isActive('userSurveyJotform')) useUserSurvey().schedule()
})

if (params.iframe) {
  useScreen().onResize(iframeResize)
}

/**
 * Sends the height to the parent iFrame so it can grow in "traditional" mode.
 *
 * @param rect New size rectangle
 */
function iframeResize({ height }: ScreenSizePair) {
  window.parent.postMessage([height, 0].join(';'), '*')
}
</script>

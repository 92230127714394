import type { DirectiveBinding } from 'vue'
import type { Handlers } from '~/resources/tracking'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('track', {
    mounted(el, binding) {
      const [group] = binding.arg?.split(':', 2) || []
      if (group === 'impression') {
        setupTrackingImpressionObserver(el, binding, useTracking().handlers!)
      }
    },
  })
  nuxtApp.vueApp.directive('impression', {
    mounted(el, binding) {
      // console.log(' *** setting up impression observer ***', binding)

      let threshold = Object.keys(binding.modifiers)
        .map((modifier) => parseInt(modifier))
        .filter((modifier) => typeof modifier === 'number')
        .map((modifier) => modifier / 100)
      if (!threshold.length) threshold = [0.5]

      let handler = ''
      if (binding.arg) {
        handler =
          binding.arg.split(':').length === 1
            ? `impression:${binding.arg}`
            : binding.arg
      }
      // if (typeof binding.value === 'string') {
      //   handler = binding.value
      // } else
      // if (typeof binding.value === 'function') {
      //   cbfunc = binding.value
      // } else {
      // const arg =
      // }
      // else if (Array.isArray(binding.value)) {
      //   if (binding.value.length >= 1 && typeof binding.value[0] === 'string' && !handler) {
      //     handler = binding.value.shift()
      //   }
      //   arg = binding.value
      // } else if (typeof binding.value === 'object') {
      // arg = binding.value.params
      // }

      setupImpressionObserver(
        el,
        threshold,
        useTracking().handlers!,
        handler,
        binding.value,
      )
    },
  })
})

function setupTrackingImpressionObserver(
  el: Element,
  binding: DirectiveBinding,
  handlers: Handlers,
) {
  if (!window.IntersectionObserver) {
    return
  }
  const thresholds = Object.keys(binding.modifiers)
    .map((modifier) => parseInt(modifier))
    .filter((modifier) => typeof modifier === 'number')
    .map((modifier) => modifier / 100)
  const options = { threshold: thresholds.length ? thresholds : [0.5] }

  const callback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (options.threshold.length === 1) {
          // Wenn mehrere thresholds definiert sind, dürfen wir nicht nach einem Event abbrechen.
          // Wenn nur einer definiert ist brechen wir ab, da hier nachfolgende Events keinen Mehrwert liefern.
          observer.disconnect()
        }

        const bindings = Array.isArray(binding.value)
          ? binding.value
          : [binding.value]
        bindings.forEach((eventBinding) =>
          callHandler(handlers, eventBinding, entry),
        )
      }
    })
  }

  const observer = new IntersectionObserver(callback, options)

  observer.observe(el)
}

function callHandler(
  handlers: Handlers,
  binding: string | { event: string; args?: unknown[]; params: unknown },
  entry: IntersectionObserverEntry,
) {
  const { event, args, params } =
    typeof binding === 'string' ? { event: binding } : binding
  const [category, name] = event.split(':')
  const handlerCategory = handlers[category as keyof typeof handlers]
  const handler = handlerCategory[
    name as keyof typeof handlerCategory
  ] as Function
  handler?.call(null, ...(args ?? []), { params, entry })
}

function setupImpressionObserver(
  el: Element,
  threshold: number[],
  handlers: Handlers,
  handler: string,
  arg: any,
) {
  // console.log(' *** setting up impression observer ***', handler, threshold, arg)
  if (!window.IntersectionObserver) {
    return
  }
  const options = { threshold }

  const callback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (options.threshold.length === 1) {
          // Wenn mehrere thresholds definiert sind, dürfen wir nicht nach einem Event abbrechen.
          // Wenn nur einer definiert ist brechen wir ab, da hier nachfolgende Events keinen Mehrwert liefern.
          observer.disconnect()
        }
        if (typeof arg === 'function') {
          arg({
            ratio: entry.intersectionRatio,
            threshold: observer.thresholds,
          })
        }
        if (handler) {
          const [category, name] = handler.split(':')
          const handlerCategory = handlers[category as keyof typeof handlers]
          // TODO: Was ist mit arg?
          ;(handlerCategory[name as keyof typeof handlerCategory] as Function)({
            data: arg,
            entry,
            thresholds: observer.thresholds,
          })
        }
      }
    })
  }

  const observer = new IntersectionObserver(callback, options)

  observer.observe(el)
}

import { useQuery } from '@tanstack/vue-query'
import {
  mapSearchApiParams,
  setDocPosition,
} from '~/composables/api/useSearchApi'
import { pick } from 'lodash-es'

export interface SearchApiParams {
  fuzzyness?: number
  language?: string
  currency?: string
  season?: Season
  page?: number
  pagesize?: number
  sorting?: SortingOptions
  brand?: string
  salesoffice?: string
  q?: string
  checkin?: string
  checkout?: string
  duration?: number
  country?: string
  region?: string
  place?: string
  code?: string[]
  geo?: string[]
  n?: number
  w?: number
  s?: number
  e?: number
  zoom?: number
  lat?: number
  lon?: number
  radius?: string
  pax?: number | string
  pets?: boolean
  house?: boolean
  apartment?: boolean
  villa?: boolean
  chalet?: boolean
  farmhouse?: boolean
  detached?: boolean
  stars?: number
  rating?: number
  reviews?: number
  bedrooms?: number
  bathrooms?: number
  sea?: number
  lake?: number
  ski?: number
  center?: number
  wlan?: boolean
  aircondition?: boolean
  parking?: boolean
  garage?: boolean
  'balcony-or-terrace'?: boolean
  dishwasher?: boolean
  washingmachine?: boolean
  tv?: boolean
  sea_or_lake_view?: boolean
  bbq?: boolean
  boat?: boolean
  cots?: boolean
  hottub?: boolean
  fireplace?: boolean
  sauna?: boolean
  wheelchair?: boolean
  charging_station?: boolean
  pool?: boolean
  pool_private?: boolean
  pool_indoor?: boolean
  pool_children?: boolean
  'min-price'?: number
  maxPrice?: number
  discount?: number
  special_offer?: boolean
  last_minute?: boolean
  early_booker?: boolean
  'discount-20'?: boolean
  cheapcheap?: boolean
  familyfriendly?: boolean
  holiday_resort?: boolean
  residence?: boolean
  citytrips?: boolean
  utoring?: boolean
  casa?: boolean
  swiss_peak?: boolean
  workation?: boolean
  sustainable?: boolean
  skiing?: boolean
  hiking?: boolean
  golfing?: boolean
  cycling?: boolean
  wellness?: boolean
  tennis?: boolean
  surfing?: boolean
  sailing?: boolean
  mountainbiking?: boolean
  riding?: boolean
  crosscountryskiing?: boolean
  fishing?: boolean
  fishing_certified?: boolean
  studio?: boolean
  lso?: boolean
  bo?: string
  rooms?: number
  facet?: (keyof Facets)[]
}

export interface SearchResult {
  _hits: ResultHits
  totalHits: number
  totalHitsRel?: string
  max_score?: number
  docs: ResultDocument[]
  facets?: Facets
  viewport?: Viewport
  _meta: ResultMeta
  _aggregations?: { [key: string]: Aggregation }
  buckets?: Bucket[]
}

export function useSearchQuery<R = SearchResult>(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: {
    enabled?: MaybeRefOrGetter<boolean | undefined>
    select?(result: SearchResult, params: SearchApiParams): R
  } = {},
) {
  const fetch = useRequestFetch()
  const params = computed(() => sortKeys(toValue(query)))
  return useQuery({
    placeholderData: (prev: SearchResult | undefined) => prev, // Keep previous data between requests
    enabled: computed(
      () => toValue(options.enabled) !== false && import.meta.client,
    ),
    queryKey: ['search-api', 'accomtrips', params] as const,
    queryFn({ queryKey: [_, __, q], signal }) {
      return fetch<SearchResult>('/search-api/v1/engines/accomtrips', {
        query: mapSearchApiParams(q),
        signal,
      })
    },
    select(result) {
      return (
        options.select ? options.select(result, toValue(query)) : result
      ) as R
    },
  })
}

type SearchMultiQuery = {
  requests: SearchApiParams[]
  language?: string
}

export function useSearchMultiQuery<R = SearchResult[]>(
  query: MaybeRefOrGetter<SearchMultiQuery>,
  options: {
    enabled?: MaybeRefOrGetter<boolean>
    select?(result: SearchResult[], params: SearchMultiQuery): R
  } = {},
) {
  const fetch = useRequestFetch()
  const params = computed(() => ({
    requests: toValue(query).requests.map(sortKeys),
    language: toValue(query).language,
  }))
  return useQuery({
    enabled: toRef(options.enabled),
    queryKey: ['search-api', 'multi', params] as const,
    queryFn({ queryKey: [_, __, { requests, language }], signal }) {
      return fetch<SearchResult[]>('/search-api/v1/engines/accomtrips/multi', {
        query: { request: requests.map(stringifyParams) },
        headers: removeFalsy({ 'Accept-Language': language }),
        signal,
      })
    },
    select(result) {
      return (
        options.select ? options.select(result, toValue(query)) : result
      ) as R
    },
  })
}

export function useSearchAccommodationsQuery(
  query: MaybeRefOrGetter<SearchApiParams>,
  options: { enabled?: MaybeRefOrGetter<boolean | undefined> } = {},
) {
  return useSearchQuery(query, {
    ...options,
    select(result) {
      return setDocPosition(result, toValue(query))
    },
  })
}

export function useSearchRegionPlacesQuery(
  query: MaybeRefOrGetter<
    Pick<SearchApiParams, 'country' | 'region' | 'facet'>
  >,
  options: { enabled?: MaybeRefOrGetter<boolean | undefined> } = {},
) {
  return useSearchAccommodationsQuery(
    computed(() => ({
      pagesize: -1,
      ...pick(toValue(query), ['country', 'region', 'facet']),
    })),
    options,
  )
}

export function useSearchLinkedObjectsQuery(params: {
  code: MaybeRef<string>
  language?: MaybeRef<string | undefined>
}) {
  const fetch = useRequestFetch()
  return useQuery({
    enabled: import.meta.client,
    queryKey: ['search-api', 'linked-objects', params] as const,
    queryFn({ queryKey: [_, __, { code, language }], signal }) {
      return fetch(`/search-api/v1/accoms/${code}/linked-objects`, {
        headers: removeFalsy({
          'Accept-Language': language,
        }),
        signal,
      })
    },
  })
}

/**
 * Converts params to a multi-search request string.
 *
 * @param params Request params.
 * @returns String in format `country:AT,pets:true,sorting:-averagerating`.
 */
function stringifyParams(params: object): string {
  return JSON.stringify(params).replace(/["{}]/g, '')
}

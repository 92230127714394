<template>
  <article
    class="rounded-lg border border-brd overflow-hidden text-wrn-contrast cursor-pointer"
    :class="[isGridView ? 'flex flex-col' : 'grid card']"
    @click.prevent.stop="openDetailPage"
  >
    <AccomCardGallery :key="layout" :accom @click.stop="openDetailPage">
      <AccomCardTags :accom :show-trip-tag class="absolute left-2 top-2" />

      <client-only>
        <AccomCardBookmark :accom class="absolute right-2 top-2" />
      </client-only>
    </AccomCardGallery>

    <main class="flex flex-col relative gap-3 p-3 leading-[18px]">
      <AccomCardEco :accom :small="isGridView" class="absolute top-3 right-3" />

      <AccomCardStars :accom />

      <AccomCardName :accom />

      <AccomCardLocation :accom />

      <AccomCardFeatures :accom />

      <AccomCardRating :accom class="mt-2" />

      <AccomCardPrice v-if="trip" :trip :currency class="mt-2" />

      <WebccButton
        v-if="!isGridView"
        variant="cta"
        class="absolute right-3 bottom-3"
        @click.stop="openDetailPage"
      >
        {{ $t(`${trPrefix}discover`) }}
      </WebccButton>
    </main>
  </article>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.'

const props = defineProps<{
  accom: ResultDocument
  layout: SearchResultLayout
  currency: string
  showTripTag?: boolean
}>()

const { buildAccommodationUrl } = useURLs()
const tracking = useTracking()
const screen = useScreen()

const isGridView = computed(() => props.layout === 'grid' || !screen.isLarge)
const trip = computed(() => props.accom.trip)
const url = computed(() => buildAccommodationUrl(props.accom).href)

function openDetailPage() {
  tracking.handlers?.ecommerce.selectItem(props.accom)
  tracking.handlers?.eec.productClick(props.accom)
  navigateTo(url.value, { open: { target: '_blank' } })
}
</script>

<style scoped>
.card {
  grid-template-columns: minmax(275px, 1fr) 2fr;
}
</style>

<template>
  <div class="flex flex-wrap items-center gap-2 text-xs font-medium">
    <div
      v-if="discount"
      class="rounded px-1 py-0.5 bg-hlt-hover text-hlt-contrast"
    >
      {{ discount }}&percnt;
    </div>
    <div v-if="isTopRated">
      <span class="rounded px-1 py-0.5 bg-suc text-suc-contrast uppercase">
        {{ $t(`${trPrefix}toprated`) }}
      </span>
    </div>
    <div v-if="isNew">
      <span class="rounded px-1 py-0.5 bg-suc text-suc-contrast uppercase">
        {{ $t(`${trPrefix}new`) }}
      </span>
    </div>
    <div v-if="showTripTag" class="rounded p-1 bg-bgr opacity-80 text-xs">
      {{ dateRangeText }}
    </div>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'www.components.hitbox.Tags.'

const props = defineProps<{
  accom: ResultDocument
  showTripTag?: boolean
}>()

const discount = computed(() => props.accom.trip?.reduction?.toFixed())

const isTopRated = computed(() => props.accom.tags.includes('top'))

const isNew = computed(() => props.accom.tags.includes('new'))

const dateRangeText = computed(() =>
  formatDateRange(getTripDateRange(props.accom.trip ?? {}), 'monthDay'),
)
</script>

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server || useConfdata().pagetype !== 'search') return

  useSearch().setFilter(parseURL(to.path), { reset: true })
})

/*
 * parseURL erstellt das Query-Objekt aus den URL- und Pfad-Parametern
 */
function parseURL(path: string): Params {
  const params = useParams()
  const confData = useConfdata()

  const { searchfilterconfs } = confData.additionalBody as SearchPageBody
  const pathelements = path.split('/').filter((p) => !!p)

  let q: Params = {
    ...getDestinationQuery(confData.destination),
    ...params.all,
  }
  for (const conf of searchfilterconfs || []) {
    if (pathelements?.includes(conf.slug.path)) {
      const filtername = conf.filter
      if (['sea', 'lake', 'ski', 'center', 'pax'].includes(filtername)) {
        q[conf.filter] = conf.slug.value
      } else {
        q[conf.filter] = 'true'
      }
    }
  }

  // Deleting falsy and iframe values
  const { iframe } = params
  q = Object.fromEntries(
    Object.entries(q)
      .filter(([, value]) => value)
      .filter(([key]) => !iframe || (key !== 'iframe' && !(key in iframe))),
  )

  return q
}

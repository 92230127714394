import clamp from 'lodash-es/clamp'

export const RATING_MIN = 0.1
export const RATING_MAX = 5
export const RATING_DELIM = '/'

export type RatingRounded = 1 | 2 | 3 | 4 | 5
export type RatingHeart = 0 | 0.5 | 1

export function roundRating(rating: number): RatingRounded | 0 {
  return rating > 0
    ? (clamp(
        Math.round(rating),
        Math.ceil(RATING_MIN),
        RATING_MAX,
      ) as RatingRounded)
    : 0
}

export function roundRatingToDecimal(rating: number): number {
  return rating > 0
    ? clamp(Math.round(rating * 10) / 10, RATING_MIN, RATING_MAX)
    : 0
}

export function formatRating(rating: number, locale?: string): string {
  if (rating <= 0) return ''

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(rating)
}

export function getRatingText(rating: number, locale?: string): string {
  if (rating <= 0) return ''

  return [formatRating(rating, locale), RATING_DELIM, RATING_MAX].join(' ')
}

export function getRatingHearts(rating: number): RatingHeart[] {
  return Array.from({ length: RATING_MAX }, (_, index) => {
    const delta = rating - index - 0.5
    if (delta < -(!index && rating ? 0.5 : 0.25)) return 0
    if (delta >= 0.25) return 1
    return 0.5
  })
}

export default function useWebccApi() {
  async function partner(jwt: string) {
    return await useFetch<Partner>('/webcc-api/v1/sales/partners', {
      headers: { jwt },
    })
  }

  async function reviews({ region, place, locale, ...params }: Params) {
    return await useFetch<ReviewsSearchResult>(
      `/webcc-api/v1/search/${locale ?? '-'}/reviews`,
      {
        query: removeFalsy({
          ...params,
          region: region && getLegacyGeoCode(region),
          place: place && getLegacyGeoCode(place),
        }),
      },
    )
  }

  async function recommendations(
    context: 'home',
    params: {
      'domain-country'?: string
      currency?: string
      language?: string
      season?: Season
      count?: number
      user?: string
    },
  ) {
    return await useFetch<PreparedItem[]>(
      `/webcc-api/v1/recommendations/${context}`,
      {
        params,
        responseType: 'json', // force JSON type due to webcc-api does not set `Content-Type` header?
      },
    )
  }

  async function accommodationImages(code: string, season: Season) {
    return await useFetch<string[]>(
      `/webcc-api/v1/accommodations/${code}/images`,
      { params: { season } },
    )
  }

  async function accommodationReviews(code: string) {
    return $fetch<Rating>(`/webcc-api/v1/accommodations/${code}/reviews`)
  }

  async function accommodationVacancies(code: string, params: Params) {
    return await useFetch<Trips>(
      `/webcc-api/v1/accommodations/${code}/vacancies`,
      { params },
    )
  }

  async function accommodationCheckTrip(code: string, params: Params) {
    return await useFetch(`/webcc-api/v1/accommodations/${code}/checktrip`, {
      params,
    })
  }

  async function translate(params: ReviewTranslationParams) {
    return await useFetch<ReviewTranslation>('/webcc-api/v1/translations', {
      method: 'POST',
      body: params,
      headers: { 'Content-Type': 'application/json' },
    })
  }

  async function emailsSubscribe(params: EmailsSubscribeParams) {
    return await useFetch('/webcc-api/v1/forms/newsletter', {
      method: 'POST',
      body: toFormData(params),
    })
  }

  async function emailsUnsubscribe(params: EmailsUnsubscribeParams) {
    return await useFetch('/webcc-api/v1/forms/newsletter/unsubscribe', {
      method: 'PUT',
      body: toFormData(params),
    })
  }

  async function emailsConfirm({ hash, ...params }: EmailsConfirmParams) {
    return await useFetch(`/webcc-api/v1/forms/emails/${hash}/confirm`, {
      method: 'POST',
      body: toFormData(params),
    })
  }

  async function emailsOptIn({ hash, ...params }: EmailsOptInParams) {
    return await useFetch(`/webcc-api/v1/forms/emails/${hash}/optin`, {
      method: 'POST',
      body: toFormData(params),
    })
  }

  async function personalCatalogCreate(params: PersonalCatalogCreateParams) {
    return await useFetch('/webcc-api/v1/forms/personalCatalog', {
      method: 'POST',
      body: toFormData(params),
    })
  }

  async function personalCatalog(id: string) {
    return await useFetch<string[]>(`/webcc-api/v1/forms/personalCatalog/${id}`)
  }

  return {
    partner,
    reviews,
    recommendations,
    accommodationImages,
    accommodationReviews,
    accommodationVacancies,
    accommodationCheckTrip,
    translate,
    emailsSubscribe,
    emailsUnsubscribe,
    emailsConfirm,
    emailsOptIn,
    personalCatalogCreate,
    personalCatalog,
  }
}

<template>
  <main
    id="page-search"
    class="bg-bgr-50 shadow-inner"
    :data-filters-count="Object.keys(filters).length"
  >
    <SearchHeader />

    <div
      v-if="$params.iframe"
      class="container-fluid-lg flex flex-row items-center justify-between"
    >
      <SearchBreadcrumb :geo="geo" />
      <BookmarkLink />
    </div>

    <!-- <SearchArea v-if="ab.isActive('newSearchUI')" :currency="confData.currency" :domain="confData.domain" :locale="useL10N().locale" /> -->

    <SearchAreaV5 class="z-1" @interaction="onInteraction($event)">
      <DetailsTrustPilot
        v-if="
          !confData.signupEnabled ||
          !confData.siteSettings?.includes('newsletter') ||
          Math.random() > 0.5
        "
        :domain="confData.domain"
        :resource="confData.sites[0]"
      />
      <SearchNewsletterSignup v-else />
    </SearchAreaV5>

    <SearchRatingBox :key="filtersKey" />

    <SearchTeaser v-if="!$params.iframe">
      <SearchBreadcrumb :geo="geo" />
      <template #searchteaser>
        <SearchObjectTeasers
          v-if="objectTeasersEnabled"
          :key="destinationFilterKey"
          :show="objectTeasersVisible"
          :destination="destination"
        />
      </template>
      <template #contentassets>
        <SearchContentAssets
          v-if="contentAssetsEnabled"
          :key="destinationFilterKey"
          :filter="destinationFilter"
        />
      </template>
    </SearchTeaser>

    <USPCards :why="additionalBody.USPCards.cards" />

    <USPWhy
      :why="[additionalBody.USPColumns.left, additionalBody.USPColumns.right]"
    />

    <FooterNewsletterSignup />
  </main>
</template>

<script setup lang="ts">
const OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY = 10
const OBJECT_TEASERS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'skiing',
  'familyfriendly',
])

const CONTENT_ASSETS_FILTERS_TO_DISPLAY = new Set([
  'sea',
  'pool',
  'pets',
  'familyfriendly',
])

const confData = useConfdata()
const search = useSearch()
const ab = useAB()
const tracking = useTracking()

const geo = computed(() => search.geoCurrent)
const filters = computed(() => removeFalsy(search.filters))
const filtersKey = computed(() => JSON.stringify(filters.value))
const searchUrl = computed(() => search.url)
const total = computed(() => search.total ?? 0)
const flexTripFilter = computed(() => useFlexTrip().filter)
const additionalBody = computed(() => confData.additionalBody as SearchPageBody)
const destination = computed(() => confData.destination)
const destinationFilter = computed(() => destination.value?.filters?.[0].key)
const destinationFilterKey = computed(() => destination.value.urlPath)
const route = computed(() => useRoute())

const objectTeasersEnabled = computed(() =>
  Boolean(
    ab.isActive('objectteaser') &&
      route.value.path && // handeleing reactivity, see https://jira.migros.net/browse/IHGWEBCC-1382
      confData.isCanonicalPage(useRequestURL()) &&
      (!destinationFilter.value ||
        OBJECT_TEASERS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)

const objectTeasersVisible = computed(
  () =>
    objectTeasersEnabled.value &&
    total.value >= OBJECT_TEASERS_MIN_RESULTS_TO_DISPLAY,
)

const contentAssetsEnabled = computed(() =>
  Boolean(
    useFlag('content-assets').value &&
      destination.value?.countryCode &&
      (!destinationFilter.value ||
        CONTENT_ASSETS_FILTERS_TO_DISPLAY.has(destinationFilter.value)),
  ),
)
watch(useRoute(), () => window?.scrollTo({ top: 0 }), {
  deep: true,
  immediate: true,
})

watch(flexTripFilter, (newFilter, oldFilter) => {
  if (
    objectsEqual(newFilter, oldFilter) ||
    Object.values(newFilter).every((v) => !v)
  )
    return
  nextTick(() => search.setFilter(newFilter as unknown as Params))
})

watch(filters, () => (useFlexTrip().range = getTripDateRange(filters.value)))

watch(searchUrl, async (newValue, oldValue) => {
  if (!newValue || newValue === oldValue) return

  useNavigation().navigateToURL(new URL(newValue))
  await confData.load()
  usePageHead().update(confData.head)
})

onMounted(() => {
  window.onbeforeunload = function () {
    // Before a page-reload the browser normally memorises the scroll-position, but on the reloaded searchpage this may have unwanted sideeffects cause of dynamic content.
    // So it is necessary to set the scroll-position to 0 before unload (which leads to position top on reload)
    window.scrollTo(0, 0)
  }
})

function onInteraction(event: {
  map?: boolean
  bookmark?: {
    accom: ResultDocument
    state: boolean
  }
}) {
  for (const key in event) {
    switch (key) {
      case 'map':
        tracking.handlers?.search.objectMap(event.map!)
        break
      case 'bookmark':
        tracking.handlers?.search.toggleBookmark({
          accom: event.bookmark!.accom.code as string,
          state: event.bookmark!.state as boolean,
        })
        break
    }
  }
}
</script>

<template>
  <div
    class="grid gap-4 sm:grid-cols-2 md:grid-cols-3 max-h-[80vH] overflow-y-auto"
  >
    <label
      v-for="{ name, title, isActive, url } in features"
      :key="name"
      class="flex items-center justify-start gap-2 rounded-xl bg-black/5 px-3 py-2 shadow-md backdrop-blur-sm hover:shadow-xl hover:backdrop-blur-lg"
    >
      <WebccSwitch :model-value="isActive" @update:model-value="toggle(name)" />
      <div class="flex flex-col">
        <span class="text-sm font-semibold">{{ title }}</span>
        <a
          class="text-xs hover:text-thm"
          :href="url"
          anchor
          target="_blank"
          title="Open in Unleash"
          >{{ name }}</a
        >
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import { capitalize, words } from 'lodash-es'

const nuxtApp = useNuxtApp()
const runtimeConfig = useRuntimeConfig()

const features = computed(() =>
  Object.entries(nuxtApp.$unleash.features)
    .map(([name, value]) => ({
      name,
      title: words(capitalize(name)).map(replaceAbbreviation).join(' '),
      isActive: !!value,
      url: `${runtimeConfig.public.unleash.featureBaseUrl}${name}`,
    }))
    .toSorted((a, b) => a.name.localeCompare(b.name)),
)

function replaceAbbreviation(word: string) {
  return /srp|pdp/i.test(word) ? word.toUpperCase() : word
}

function toggle(name: string) {
  nuxtApp.$unleash.toggle(name)
}
</script>
